import { isAfter, isFuture, isPast, format, parseISO } from "date-fns";
import { combinePartialCfisToCfi } from "../../reader/utils";
import ePub from "epubjs";

export function normalizeNum(value, min, max) {
  if (value - min === 0 || max - min === 0) return 0;
  else return ((value - min) * 100) / (max - min);
}

export function normalize(value, min, max) {
  // Normalize the function to 100
  // we can add a fourth arg for accuracy if
  return Number(normalizeNum(value, min, max).toFixed(1));
}

export function getStudentIdsFromSubmissions(submissions) {
  // Input: submissions array
  // Output: Array if unique student ids

  return submissions.reduce((accumulator, current) => {
    // generate an array of all the student how submitted a task
    const currentStudent = current.owner;
    if (!accumulator.includes(currentStudent)) {
      accumulator.push(currentStudent);
    }
    return accumulator;
  }, []);
}

export function calculateSubmissionsGradeAvarage(submissions, tasks) {
  // Input: submissions array
  // Output: number that represent the avarage grade of submissions, scaled to 100%
  const taskMaxGrade = tasks.reduce((accumulator, current) => {
    accumulator[current.id] = current.max_grade;
    return accumulator;
  }, {});

  const { sum, count } = submissions.reduce(
    (accumulator, current) => {
      const grade =
        (100.0 * (current.grade || 0)) / taskMaxGrade[current.task_id];

      accumulator.sum += grade;
      accumulator.count++;

      return accumulator;
    },
    { sum: 0, count: 0 }
  );

  return count > 0 ? Math.round(sum / count) : NaN;
}

export function calculateStudentsAvarages(students, submissions, tasks) {
  // Input: students ids array and submissions array
  // Output: object {user: string (uid), avarage: number }

  return students.reduce((accumulator, current) => {
    const studentSubmissions = submissions.filter(
      (submission) => submission.owner === current
    );

    if (studentSubmissions.length > 0) {
      const gradeAvarage = calculateSubmissionsGradeAvarage(
        studentSubmissions,
        tasks
      );
      accumulator.push({
        user: current,
        avarage: gradeAvarage,
        displayName: studentSubmissions[0].user_name
      });
    }

    return accumulator;
  }, []);
}

export function bucketSubmissionByStatus(submissions, dueDate) {
  const stats = submissions.reduce((accumulator, current) => {
    const dueDate = new Date(current.due_date);
    const submissionDate = current.submission_date
      ? new Date(current.submission_date)
      : null;

    if (!submissionDate && isPast(dueDate)) {
      return accumulator.missed
        ? { ...accumulator, missed: accumulator.missed + 1 }
        : { ...accumulator, missed: 1 };
    } else if (!submissionDate && isFuture(dueDate)) {
      return accumulator.pending
        ? { ...accumulator, pending: accumulator.pending + 1 }
        : { ...accumulator, pending: 1 };
    } else if (isAfter(submissionDate, dueDate)) {
      return accumulator.late
        ? { ...accumulator, late: accumulator.late + 1 }
        : { ...accumulator, late: 1 };
    } else {
      return accumulator.onTime
        ? { ...accumulator, onTime: accumulator.onTime + 1 }
        : { ...accumulator, onTime: 1 };
    }
  }, {});
  return stats;
}

export function setHoveredBarLabel(
  d,
  barAttributes,
  selectedGraph,
  totalUsers,
  activeUsers,
  graphType
) {
  const rangeDifference = d[1] - d[0];
  let key, date, formattedDate, percentage, msg;

  if (graphType === "barGraph") {
    date = parseISO(d.dueDate);
    formattedDate = format(date, "dd/MM/yyyy HH:mm");
    percentage = Math.round((activeUsers / totalUsers) * 100).toFixed(0);
  } else {
    date = parseISO(d.data.dueDate);
    formattedDate = format(date, "dd/MM/yyyy HH:mm");
    percentage = Math.round((activeUsers / totalUsers) * 100).toFixed(0);

    msg =
      selectedGraph === "Percentage"
        ? `${((rangeDifference / totalUsers) * 100).toFixed(2)}%`
        : rangeDifference;

    // Find the key corresponding to the hovered segment in d.data
    for (const [k, value] of Object.entries(d.data)) {
      if (value === rangeDifference && k !== "week") {
        key = k;
        break;
      }
    }
  }

  // Calculate tooltip position
  const x = barAttributes.x(d) + barAttributes.width / 2;
  const y = barAttributes.y(d);
  const taskId = graphType === "barGraph" ? d.task_id : d.data.week.slice(1);
  return {
    taskName: graphType === "barGraph" ? d.taskName : d.data.taskName,
    taskType: graphType === "barGraph" ? d.taskType : d.data.taskType,
    dueDate: formattedDate,
    activeStudentsPercentage: percentage,
    taskId: taskId.toString(),
    totalUsers,
    activeUsers,
    rangeDifference,
    selectedGraph,
    graphType,
    key,
    msg,
    x,
    y
  };
}

export const setHoveredBarLabelForPublishedTasks = (
  event,
  d,
  selectedGraph,
  data
) => {
  // Calculate total sum of numeric values in d.data, excluding 'week'
  const total = Object.entries(d.data)
    .filter(
      ([key, val]) => key !== "week" && typeof val === "number" && !isNaN(val)
    )
    .reduce((acc, [, val]) => acc + val, 0);

  const value = d[1] - d[0];
  const percentage = (value / total) * 100;

  // Calculate the difference between d[0] and d[1]
  const difference = Math.abs(d[1] - d[0]);

  // Find the key in d.data whose value is closest to the calculated difference
  let closestKey;
  let closestDifference = Infinity;
  let hoveredValue;

  for (const [key, val] of Object.entries(d.data)) {
    if (typeof val === "number" && !isNaN(val)) {
      const diff = Math.abs(val - difference);
      if (diff < closestDifference) {
        closestDifference = diff;
        closestKey = key;
      }
    }
  }

  // Use the identified key and week to find the value in data
  const hoveredWeek = d.data.week;
  const weekData = data.find((weekObj) => weekObj.week === hoveredWeek);

  if (weekData && closestKey) {
    hoveredValue = weekData[closestKey];
  } else {
    console.error(
      `Week ${hoveredWeek} not found in data or closest key is undefined.`
    );
  }

  const date = parseISO(weekData.dueDate);
  const formattedDate = format(date, "dd/MM/yyyy HH:mm");

  const x = event.offsetX - 100;
  const y = event.offsetY;
  const graphData = d.data;
  return {
    percentage: percentage.toFixed(1),
    taskName: weekData.taskName,
    taskType: weekData.taskType,
    dueDate: formattedDate,
    value: hoveredValue,
    roundedValue: Math.round(value),
    x,
    y,
    selectedGraph,
    graphData
  };
};

const getColorFromFreq = (frequence, totalCount, colors) => {
  let index = Math.floor(((frequence * 1.0) / totalCount) * 10) - 1;
  if (index < 0) index = 0;
  return colors[index];
};

export const getRanges = (highlights, totalCount, colors) => {
  const EpubCFI = new ePub.CFI();
  let coll = [];
  highlights &&
    highlights
      .filter((h) => h && h.cfi)
      .flatMap((h) => {
        if (h.isPdf) {
          // split multi page highlights to their parts
          let col = [];
          h.pdfPosition.forEach((pdf) => {
            col.push({ ...pdf, pdfPosition: [pdf], content: h.content });
          });
          return col;
        } else {
          return [h];
        }
      })
      .forEach((hl) => {
        let cfiParts = hl.cfi.split(","); // cfiBase:  cfiParts[0]
        let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let endCfi = cfiParts[0] + cfiParts[2];
        coll.push({ ...hl, cfi: startCfi, val: 1 });
        coll.push({ ...hl, cfi: endCfi, val: -1 });
      });

  let sortedHl = [...coll].sort(function (a, b) {
    let val = EpubCFI.compare(a.cfi, b.cfi);
    if (val === 0) {
      if (a.val === b.val) return 0;
      return b.val < 0 ? 1 : -1;
    }
    return val;
  });

  let current = 0;
  let start = null;
  let frequencies = [];
  for (let i = 0; i < sortedHl.length; i++) {
    if (start == null) {
      if (sortedHl[i].val > 0) {
        start = sortedHl[i].cfi;
        current = 1;
      }
    } else {
      frequencies.push({
        ...sortedHl[i],
        start: start,
        end: sortedHl[i].cfi,
        freq: current
      });
      start = sortedHl[i].cfi;
      if (sortedHl[i].val > 0) {
        current++;
      } else {
        current--;
        if (current === 0) {
          start = null;
        }
      }
    }
  }

  //freq to colors :
  const output = frequencies.map((el, i) => {
    const isPdf = el?.isPdf || false;
    const cfi = combinePartialCfisToCfi(el.start, el.end, { isPdf });

    if (el.pdfPosition) {
      el.pdfPosition = [{ ...el.pdfPosition[0], cfi: cfi }];
    }

    return {
      ...el,
      cfi,
      color: getColorFromFreq(el.freq, totalCount, colors)
    };
  });
  return output;
};
