import { useEffect, useState } from "react";

export const useStudentSubmissions = (userBasedSubmissions, userList) => {
  const userListIds = userList.map(
    (user) => user?.course_user || user?.uid || user?.id || user
  );
  const [newUserBased, setNewUserBased] = useState({});

  useEffect(() => {
    if (userBasedSubmissions) {
      setNewUserBased(
        Object.fromEntries(
          Object.entries(userBasedSubmissions).flatMap(([userId, value]) => {
            if (userListIds.includes(userId)) return [[userId, value]];
            else return [];
          })
        )
      );
    } else {
      setNewUserBased({});
    }
  }, [userBasedSubmissions, userList]);

  return newUserBased;
};
