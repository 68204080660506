import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";
import { useGetTheme } from "../../../hooks";

const AssignmentOverview = ({
  activeCourseStudents,
  activeTaskStudents,
  submissionRate,
  engagement
}) => {
  const theme = useGetTheme();
  const items = [
    {
      title: "Students active in course",
      value: activeCourseStudents
    },
    {
      title: "Students active in assignment",
      value: activeTaskStudents
    },
    {
      title: "Submission rate",
      value: `${parseFloat(submissionRate).toFixed(0)}%`
    },
    {
      title: "Average time of engagement",
      value: `${engagement} min`
    }
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        margin: 0,
        padding: 0
      }}>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            flex: "1 1 calc(25% - 16px)",
            marginInlineEnd: index % 4 !== 3 ? "16px" : 0,
            height: "130px",
            border: `1px solid ${theme.palette.border.secondary}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            backgroundColor: theme.palette.background.paper
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexFlow: "column",
              height: "100%",
              padding: "10%",
              textAlign: "center"
            }}>
            <Typography variant="p" sx={{}}>
              {item.title}
            </Typography>
            <Typography variant="h4">{item.value}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AssignmentOverview;
