import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { useChartDimensions, useGetTheme } from "../../../../hooks";
import { Box } from "@mui/material";
import { reverseNormalizeGrade } from "../utils"; // Assuming you have this utility function defined

const LineChartBody = ({ data, courseAssignments }) => {
  const svgRef = useRef();
  const { ref, dimensions } = useChartDimensions();
  const theme = useGetTheme();
  const isDark = theme.palette.mode === "dark";
  useEffect(() => {
    // Processing the data

    const gradesByTask = data.gradesByTask;
    const maxGrades = data.maxGrades;

    const processedData = Object.keys(maxGrades).map((key, index) => {
      const assignment = courseAssignments.filter(
        (assignment) => assignment.id === parseInt(key)
      );
      const task = gradesByTask[key];
      const maxGrade = maxGrades[key];

      if (task?.length > 0 && maxGrade) {
        const average = d3.mean(task) || 0;
        const min = d3.min(task) || 0;
        const max = d3.max(task) || 0;

        return {
          index: index,
          average: average,
          normalizedAverage: Math.round(
            reverseNormalizeGrade(average, maxGrade)
          ),
          normalizedMin: Math.round(reverseNormalizeGrade(min, maxGrade)),
          normalizedMax: Math.round(reverseNormalizeGrade(max, maxGrade)),
          valid: true,
          name: assignment[0]?.name,
          originalGrade: assignment[0]?.max_grade
        };
      } else {
        return {
          index: index,
          average: 0,
          normalizedAverage: 0,
          normalizedMin: 0,
          normalizedMax: 0,
          valid: false,
          name: assignment[0]?.name,
          originalGrade: assignment[0]?.max_grade
        };
      }
    });
    // Dimensions and margins of the graph
    const margin = { top: 50, right: 30, bottom: 50, left: 50 },
      width = 850 - margin.left - margin.right,
      height = 350 - margin.top - margin.bottom;

    // Clear any previous chart
    d3.select(svgRef.current).selectAll("*").remove();

    // Append the svg object to the ref element
    const svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .style("overflow", "hidden")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Y scale
    const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);

    const x = d3
      .scaleLinear()
      .domain([0, processedData.length - 1]) // Set domain to exact length of data - 1
      .range([30, width - margin.left - margin.right * 1.5]);

    // X axis
    svg
      .append("g")
      .style("overflow", "hidden")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3
          .axisBottom(x)
          .tickValues(d3.range(processedData.length)) // Only show ticks for actual data points
          .tickFormat((d) => d + 1) // Show assignment numbers starting from 1
      )
      .append("text")
      .attr("class", "x-axis-label")
      .attr("x", width)
      .attr("dy", "1.5em")
      .attr("dx", "-1em")
      .style("text-anchor", "center")
      .attr("fill", theme.palette.text.primary)
      .style("font-size", "14px")
      .text("Assignments");

    // Y axis with ticks every 20%
    svg
      .append("g")
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
          .tickFormat((d) => `${d}%`)
      )
      .append("text")
      .attr("class", "axis-label")
      .attr("y", -20)
      .attr("x", 15)
      .attr("dx", "-1em")
      .attr("dy", "-1em")
      .style("text-anchor", "center")
      .style("fill", theme.palette.text.primary)
      .style("font-size", "14px")
      .text("Grades");

    svg
      .selectAll("text")
      .style("font-size", "14px")
      .style("color", theme.palette.text.primary)
      .style("font-family", "Chivo")
      .style("font-weight", "400");
    svg.selectAll(".tick line").remove();

    // Add lines across the chart
    svg
      .selectAll(".tick")
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", width + margin.left)
      .attr("y2", 0)
      .attr("stroke", "lightgray");

    // Filter valid data for the area and line
    const validData = processedData.filter((d) => d.valid);

    // Blue area for min to max
    svg
      .append("path")
      .datum(validData)
      .attr(
        "fill",
        isDark ? theme.palette.primary.light : "rgba(2, 136, 209, 0.1)"
      )
      .attr("stroke", "none")
      .attr(
        "d",
        d3
          .area()
          .x((d) => x(d.index))
          .y0((d) => y(d.normalizedMin))
          .y1((d) => y(d.normalizedMax))
      );

    // Line for averages
    svg
      .append("path")
      .datum(validData)
      .attr("fill", "none")
      .attr("stroke", "#003C8F")
      .attr("stroke-width", 4)
      .attr(
        "d",
        d3
          .line()
          .x((d) => x(d.index))
          .y((d) => y(d.normalizedAverage))
      );

    const tooltip = d3
      .select(svgRef.current)
      .append("div")
      .attr("class", "tooltip")
      .style("display", "none")
      .style("opacity", 0)
      .style("color", "#fff")
      .style("padding", "4px 8px")
      .style("border-radius", "5px")
      .style("background-color", "rgba(97, 97, 97, 0.9)")
      .style("position", "absolute")
      .style("font-size", "12px");

    svg
      .selectAll("circle")
      .data(validData) // Filter out invalid data
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.index))
      .attr("cy", (d) => y(d.normalizedAverage))
      .attr("r", 6)
      .attr("fill", "white")
      .attr("stroke", "#003C8F")
      .attr("stroke-width", 3)
      .on("mouseover", (event, d) => {
        tooltip
          .style("display", "block")
          .transition()
          .duration(200)
          .style("opacity", 0.9);
        tooltip.html(
          `${d.name}  <br/>  Average grade: ${d.normalizedAverage.toFixed(
            0
          )}% <br/>  Original grade: ${Number(d.average).toFixed(0)} out of ${
            d.originalGrade
          }`
        );
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", event.pageX - 80 + "px")
          .style("top", event.pageY + 30 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });

    svg.selectAll(".domain").remove(); // Remove x-axis and y-axis lines

    return () => {
      tooltip.remove();
    };
  }, [data, dimensions, courseAssignments, theme, isDark]);

  return (
    <Box
      ref={ref}
      style={{ overflow: "hidden", width: "100%", height: "100%" }}>
      <div ref={svgRef} />
    </Box>
  );
};

export default LineChartBody;
