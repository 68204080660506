import React, { useState, useEffect } from "react";
import { TABLE_VIEW } from "../reports/consts";
import { calculateSubmissionStatus } from "../reports/utils";
import PieChart from "../reports/pieChart/PieChart";
import { Box, Typography, IconButton } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import useGetTheme from "../../../hooks/useGetTheme";

const ToggleButton = ({ condition, handleClick, label }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <IconButton
      aria-label={`${label}: ${condition === true ? "on" : "off"}`}
      onClick={handleClick}
      size="large">
      {condition ? (
        <ToggleOnIcon fontSize="large" color="primary" />
      ) : (
        <ToggleOffIcon fontSize="large" />
      )}
    </IconButton>
    <Typography variant="subtitle2">{label}</Typography>
  </Box>
);

const StudentSubmissionsReport = ({ submissions }) => {
  const [includeFutureAssignments, setIncludeFutureAssignments] =
    useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState(submissions);
  const [totalSelectedSubmissions, setTotalSelectedSubmissions] = useState(0);
  const theme = useGetTheme();

  useEffect(() => {
    let updatedSubmissions = parseSubmissions(submissions);

    if (!includeFutureAssignments) {
      const totalPending =
        updatedSubmissions.statusBased.SubmissionPending.total;
      updatedSubmissions.statusBased.SubmissionPending.total = 0;
      updatedSubmissions.totalSubmissions -= totalPending;
      setTotalSelectedSubmissions(updatedSubmissions.totalSubmissions);
    } else {
      setTotalSelectedSubmissions(updatedSubmissions.totalSubmissions);
    }
    setSelectedSubmissions(updatedSubmissions);
  }, [includeFutureAssignments, submissions]);

  function parseSubmissions(submissions) {
    // Initialize counters
    const statusCounts = {
      SubmissionOnTime: { complete: 0 },
      SubmissionPending: { total: 0 },
      SubmissionMissed: { total: 0 },
      SubmissionLate: { complete: 0 }
    };

    submissions.forEach((submission) => {
      const status = calculateSubmissionStatus(submission);

      // Count based on status
      if (status === "submitted") {
        // Assuming all "Submitted" are on time or late
        statusCounts.SubmissionOnTime.complete += 1;
      } else if (status === "pending") {
        // Active tasks, yet to be submitted, are counted as pending
        statusCounts.SubmissionPending.total += 1;
      } else if (status === "late") {
        // Tasks that are pending
        statusCounts.SubmissionLate.complete += 1;
      } else if (status === "missed") {
        // Missed tasks
        statusCounts.SubmissionMissed.total += 1;
      }
    });

    // Calculate total submissions
    const totalSubmissions = submissions.length;

    // Return the parsed data in the desired format
    return {
      statusBased: statusCounts,
      totalSubmissions: totalSubmissions
    };
  }

  return (
    <Box
      sx={{
        paddingInline: "24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">
          Total submissions: {totalSelectedSubmissions}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ToggleButton
            condition={includeFutureAssignments}
            handleClick={() =>
              setIncludeFutureAssignments((prevState) => !prevState)
            }
            label="Include pending assignments"
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box sx={{ height: "80%", paddingBlockStart: "30px" }}>
          <PieChart
            type="submissions"
            data={selectedSubmissions}
            totalSubmissions={totalSelectedSubmissions}
            includeFutureAssignments={includeFutureAssignments}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StudentSubmissionsReport;
