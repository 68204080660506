import React, { useState } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { truncateMiddle } from "../utils";
const useStyles = makeStyles({
  paper: {
    autoFocus: false,
    maxHeight: "200px",
    overflowY: "scroll"
  }
});

const TasksDropDownSelection = ({
  assignments,
  setSelectedAssignment,
  selectedAssignment
}) => {
  const classes = useStyles();

  if (!assignments.length) return;

  function handleSelectedAssignmentChange(event) {
    setSelectedAssignment(event.target.value);
  }

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 150, marginInlineStart: "32px" }}>
        <Select
          labelId="assignment-label"
          id="assignment-select"
          variant="standard"
          value={selectedAssignment || ""}
          label="Assignments"
          autoWidth
          MenuProps={{
            classes: {
              paper: classes.paper
            }
          }}
          onChange={handleSelectedAssignmentChange}>
          {assignments.map((assignment) => (
            <MenuItem key={assignment.id} value={assignment}>
              {truncateMiddle(assignment.name, 30)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default TasksDropDownSelection;
