import React, { useEffect, useState, useCallback, memo, useMemo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Chip, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

const useStyles = makeStyles((theme) => ({
  pending: {
    backgroundColor: theme.palette.grey[300]
  },
  teacher: {
    backgroundColor: theme.palette.engagementChip.teacher.background,
    color: theme.palette.engagementChip.teacher.color
  },
  Low: {
    backgroundColor: theme.palette.engagementChip.Low.background,
    color: theme.palette.engagementChip.Low.color
  },
  Moderate: {
    backgroundColor: theme.palette.engagementChip.Moderate.background,
    color: theme.palette.engagementChip.Moderate.color
  },
  VeryHigh: {
    backgroundColor: theme.palette.engagementChip.VeryHigh.background,
    color: theme.palette.engagementChip.VeryHigh.color
  },
  High: {
    backgroundColor: theme.palette.engagementChip.High.background,
    color: theme.palette.engagementChip.High.color
  },
  risk: {
    backgroundColor: theme.palette.engagementChip.risk.background,
    fontWeight: 500,
    color: theme.palette.engagementChip.risk.color,
    paddingInline: theme.spacing(0.5)
  }
}));

const EngagementChip = memo(function EngagementChip({
  usersBySubmissionStatus,
  timeSpentOnAssignmentData,
  userId,
  maxPagePerTextData,
  rawSubmissions,
  setStudentsAtRiskIds
}) {
  const classes = useStyles();

  const [label, setLabel] = useState("");
  const [style, setStyle] = useState(null);
  const [userSubmittedAssignmentIds, setUserSubmittedAssignmentIds] = useState(
    []
  );
  const [userTimeOnAssignment, setUserTimeOnAssignment] = useState({});
  const [readingScoreForUser, setReadingScoreForUser] = useState(0);

  const getWordCountPerText = useCallback((taskId, tasksData) => {
    const task = tasksData.find((task) => task.task_id === taskId);
    return task ? task.max_page * 300 : 1;
  }, []);

  const sumDurationsToSecondsByTaskId = useCallback((timeMetrics) => {
    const taskDurations = {};

    for (const metricType in timeMetrics) {
      timeMetrics[metricType].forEach((entry) => {
        taskDurations[metricType] = taskDurations[metricType] || {};
        taskDurations[metricType][entry.task_id] =
          (taskDurations[metricType][entry.task_id] || 0) +
          entry.duration / 1000;
      });
    }

    return taskDurations;
  }, []);

  const calculateScore = useCallback(
    (wordCount, readingTime, secondsPerPage) => {
      const requiredTimePer300Words = secondsPerPage;
      if (readingTime < requiredTimePer300Words) {
        return 0;
      }

      const ratio = readingTime / (wordCount / requiredTimePer300Words);
      return Math.min(1, Math.round(ratio * 10) / 10);
    },
    []
  );

  const getScoresForTasks = useCallback(() => {
    if (!userSubmittedAssignmentIds.length) return 0;

    const scores = userSubmittedAssignmentIds.map((taskId) => {
      const wordCount = getWordCountPerText(taskId, maxPagePerTextData);
      const readingTime = userTimeOnAssignment?.HIGHLIGHT_TIME?.[taskId] || 0;
      return calculateScore(wordCount, readingTime, 60);
    });

    return scores.length
      ? scores.reduce((total, value) => total + value, 0) / scores.length
      : 0;
  }, [
    userSubmittedAssignmentIds,
    maxPagePerTextData,
    userTimeOnAssignment,
    getWordCountPerText,
    calculateScore
  ]);

  const filterByUserId = useCallback((data, userId) => {
    const filteredData = {};

    for (const category in data) {
      filteredData[category] = data[category].filter(
        (item) => item.user_id === userId
      );
    }

    return filteredData;
  }, []);

  const calculateEngagement = useCallback(
    (user) => {
      const { submitted, missed, pending, late } = user.submissionStatusCount;
      const totalSubmissions = submitted + missed + late;

      const submittedOnTimePercentage = (submitted / totalSubmissions) * 100;
      const submittedPercentage = ((submitted + late) / totalSubmissions) * 100;

      let engagementLevel = "Low";

      if (submittedOnTimePercentage >= 90) {
        engagementLevel = "Very High";
      } else if (submittedPercentage >= 75) {
        engagementLevel = "High";
      } else if (submittedPercentage >= 50) {
        engagementLevel = "Moderate";
      }

      if (submittedPercentage < 20) {
        setStudentsAtRiskIds((prev) => {
          const existingUser = prev.find((item) => item.user_id === user.id);

          if (!existingUser) {
            const missedSubmissionsPercentage =
              (missed / totalSubmissions) * 100;
            const warning = "Time spent: below average";

            return [
              ...prev,
              {
                user_id: user.id,
                percentage: missedSubmissionsPercentage,
                warning
              }
            ];
          }

          return prev;
        });

        engagementLevel = "risk";
      }

      return engagementLevel;
    },
    [readingScoreForUser, setStudentsAtRiskIds]
  );

  useEffect(() => {
    if (!usersBySubmissionStatus?.length) return;

    // Get submitted assignments
    const submittedAssignmentIds = rawSubmissions
      .filter((submission) => submission.submission_date)
      .map((submission) => submission.task_id);

    // Get time on assignment
    const filteredTimeOnAssignment = filterByUserId(
      timeSpentOnAssignmentData,
      userId
    );
    const summedDurations = sumDurationsToSecondsByTaskId(
      filteredTimeOnAssignment
    );

    // Set initial states
    setUserSubmittedAssignmentIds(submittedAssignmentIds);
    setUserTimeOnAssignment(summedDurations);
  }, [
    usersBySubmissionStatus,
    timeSpentOnAssignmentData,
    userId,
    rawSubmissions,
    filterByUserId,
    sumDurationsToSecondsByTaskId
  ]);

  // Separate effect for reading score calculation
  useEffect(() => {
    if (
      !userSubmittedAssignmentIds.length ||
      !Object.keys(userTimeOnAssignment).length
    )
      return;

    const score = getScoresForTasks();
    setReadingScoreForUser(score);
  }, [userSubmittedAssignmentIds, userTimeOnAssignment, getScoresForTasks]);

  // Separate effect for engagement calculation
  useEffect(() => {
    if (
      !usersBySubmissionStatus?.length ||
      typeof readingScoreForUser !== "number"
    )
      return;

    const engagementLevel = calculateEngagement(usersBySubmissionStatus[0]);
    const formattedStyle =
      typeof engagementLevel === "string"
        ? engagementLevel.trim().replace(/\s+/g, "")
        : "";
    const formattedLabel = engagementLevel.replace(/([A-Z])/g, " $1").trim();

    setStyle(formattedStyle);
    setLabel(formattedLabel);
  }, [usersBySubmissionStatus, readingScoreForUser, calculateEngagement]);

  const ChipContent = useMemo(() => {
    return !label || !style ? (
      <Skeleton height={40} width={42} sx={{ borderRadius: "16px" }} />
    ) : (
      <Chip
        className={clsx(classes.chip, style && classes[style])}
        label={label === "risk" ? "Student at risk" : label}
        size="small"
        icon={
          label === "risk" ? (
            <FlagOutlinedIcon style={{ color: "rgba(211, 47, 47, 1)" }} />
          ) : (
            <></>
          )
        }
        clickable={false}
      />
    );
  }, [style, label, classes]);

  return ChipContent;
});

EngagementChip.propTypes = {
  usersBySubmissionStatus: PropTypes.array.isRequired,
  timeSpentOnAssignmentData: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  maxPagePerTextData: PropTypes.array.isRequired,
  rawSubmissions: PropTypes.array.isRequired,
  setStudentsAtRiskIds: PropTypes.func.isRequired
};

export default EngagementChip;
