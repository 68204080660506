// Dependencies
import React, { useMemo } from "react";

// Components
import PieChartLegend from "./PieChartLegend";
import PieChartBody from "./PieChartBody";

// Hooks
import { useGetTheme } from "../../../../hooks";

//Redux

// Material-UI
import { Box } from "@mui/material";

// Helper functions
import {
  flattenObject,
  transformSubmissionsData,
  sortSubmissionsObject,
  removeFutureAssignments,
  sortTimeSpentOnAssignmentObject,
  adjustPercentages
} from "../utils";

function PieChart({
  type,
  data,
  totalSubmissions,
  includeFutureAssignments = true,
  courseAssignments = []
}) {
  const isSubmissionsType = type === "submissions";

  // Hooks
  const theme = useGetTheme();

  // Redux

  // Ephemeral state
  const chartData = useMemo(() => {
    if (data === undefined || data.length < 1) return;
    const transformedData = transformSubmissionsData(
      Object.entries(
        flattenObject(
          type === "submissions"
            ? sortSubmissionsObject(data?.statusBased)
            : data
        )
      ),
      theme
    );

    return transformedData;
  }, [data, theme]);

  const legendData = useMemo(() => {
    let sortedSubmissions;
    if (isSubmissionsType) {
      sortedSubmissions = sortSubmissionsObject(
        includeFutureAssignments
          ? data.statusBased
          : removeFutureAssignments(data.statusBased).cleanedData
      );
    } else {
      sortedSubmissions = sortTimeSpentOnAssignmentObject(data);
    }

    const rawData = Object.entries(flattenObject(sortedSubmissions)).map(
      (entry) => ({
        cat: entry[0],
        val: entry[1]
      })
    );

    const chartDataPercentages = adjustPercentages(rawData, totalSubmissions);
    return chartDataPercentages;
  }, [data, includeFutureAssignments, isSubmissionsType]);
  // Behavior
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
        alignItems: "flex-start"
      }}>
      <PieChartLegend type={type} legendData={legendData} />
      <PieChartBody
        type={type}
        chartData={chartData}
        courseAssignments={courseAssignments}
      />
    </Box>
  );
}

export default PieChart;
