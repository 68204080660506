import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import PieChart from "../../SharedComponents/reports/pieChart/PieChart";
import { useGetTheme } from "../../../hooks";
import { unifyIncompleteToComplete } from "../../SharedComponents/reports/utils";

const ToggleButton = ({ condition, handleClick, label }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <IconButton
      aria-label={`${label}: ${condition === true ? "on" : "off"}`}
      onClick={handleClick}
      size="large">
      {condition ? (
        <ToggleOnIcon fontSize="large" color="primary" />
      ) : (
        <ToggleOffIcon fontSize="large" />
      )}
    </IconButton>
    <Typography variant="subtitle2">{label}</Typography>
  </Box>
);

const SubmissionsStatusesStats = ({ submissions, totalSubmissions }) => {
  const theme = useGetTheme();

  const [includeIncompleteAssignments, setIncludeIncompleteAssignments] =
    useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState(
    unifyIncompleteToComplete(submissions)
  );

  return (
    <Box
      sx={{
        paddingInline: "24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">
          Assignment submission statuses
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ToggleButton
            condition={includeIncompleteAssignments}
            handleClick={() => {
              setIncludeIncompleteAssignments(!includeIncompleteAssignments);
              setSelectedSubmissions(
                includeIncompleteAssignments
                  ? unifyIncompleteToComplete(submissions)
                  : submissions
              );
            }}
            label="Indicate incomplete assignments"
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: "24px" }}>
        <PieChart
          type="submissions"
          data={selectedSubmissions}
          totalSubmissions={totalSubmissions}
        />
      </Box>
    </Box>
  );
};

export default SubmissionsStatusesStats;
