import React, { useState } from "react";
import { Box, Popover, IconButton } from "@mui/material";
import RichTooltip from "./RichTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const RichTooltipPopover = ({ name, readOnly, ...tooltipProps }) => {
  const id = `${name ? `${name.toLowerCase().split(" ").join("-")}-` : ""}tooltip`;
  const [anchorEl, setAnchorEl] = useState(null);
  const showTooltip = Boolean(anchorEl);
  const tooltipId = showTooltip ? "tooltip-popover" : undefined;

  const handleTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        color="primary"
        disabled={!!readOnly}
        onClick={(event) => handleTooltipClick(event)}
        aria-label={name}>
        <InfoOutlinedIcon size="small" />
      </IconButton>
      {showTooltip && (
        <Popover
          id={tooltipId}
          open={showTooltip}
          anchorEl={anchorEl}
          onClose={() => handleTooltipClose(setAnchorEl)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          aria-labelledby={id}>
          <RichTooltip id={id} {...tooltipProps} />
        </Popover>
      )}
    </Box>
  );
};
