import React from "react";
import { useGetTheme } from "../../../hooks";
import { Box, Typography } from "@mui/material";
import PieChart from "../../SharedComponents/reports/pieChart/PieChart";
import GradeHistogram from "../../SharedComponents/reports/histogram/GradeHistogram";

const GradeDistributionStats = ({ data, courseStudents, assignment }) => {
  const theme = useGetTheme();

  return (
    <Box
      sx={{
        paddingInline: "24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "60px",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">Grade distribution</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
        <GradeHistogram
          data={data}
          courseStudents={courseStudents}
          selectedAssignment={assignment}
          fullwidth
        />
      </Box>
    </Box>
  );
};

export default GradeDistributionStats;
