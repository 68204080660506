// Dependencies
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef
} from "react";
import { useGetTheme, useQuery } from "../../../../hooks";
import { useNavigate } from "react-router";
import { isEmpty, truncate, fill } from "lodash-es";
import { httpCallables } from "../../../../firebase";
import { addSnackbar } from "../../../../redux/snackbarSlice";
import { captureException } from "../../../../utils/errorHandlers";
import { systemTimezoneToUtc } from "../../../../utils/dateUtils";
import {
  sortCurrentUsersIdsBySubmissionStatus,
  sortUsersBySubmissionStatus
} from "../../../admin/reports/courseActivity/utils";

// Components
import TablePaginationFooter from "../../../admin/reports/courseActivity/TablePaginationFooter";
import RichTooltip from "../../RichTooltip";
import AssignmentSubmissions from "./AssignmentSubmissions";
import StudentEngagementChip from "./StudentEngagementChip";
// Redux
import { useDispatch, useSelector } from "react-redux";

// Material-UI
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Skeleton,
  Popover,
  Link
} from "@mui/material";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useIntl } from "react-intl";
import TooltipWithIntl from "../../tooltip/TooltipWithIntl";
import { RichTooltipPopover } from "../../RichTooltipPopover";
import { CHART_NAMES } from "../../../admin/reports/consts";
import { setPersistent } from "../../../../redux/userSlice.js";

const TableHeadComponent = ({
  title,
  handleTooltipClose,
  handleTooltipClick,
  showPopover,
  showPopoverAnchor,
  setShowPopoverAnchor,
  intl,
  showOnlyTitle = false
}) => {
  const STUDENT_TYPES = {
    "Excluded students": {
      popoverId: "excluded-students-popover",
      popoverTitle: "analytics.excludeUsersTitle",
      popoverBody: "analytics.excludeUsers",
      ariaLabel: "Show excluded students information"
    },
    "Inactive students": {
      popoverId: "inactive-students-popover",
      popoverTitle: "analytics.inactiveUsersTitle",
      popoverBody: "analytics.inactiveUsers",
      ariaLabel: "Show inactive students information"
    }
  };
  const { popoverId, popoverTitle, popoverBody, ariaLabel } =
    STUDENT_TYPES[title] || {};

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell></TableCell> */}
        <TableCell>
          {title}
          {popoverId && (
            <IconButton
              onClick={(event) =>
                handleTooltipClick(event, setShowPopoverAnchor)
              }
              aria-expanded={showPopover}
              aria-label={ariaLabel}
              aria-controls={popoverId}
              size="small"
              sx={{
                marginLeft: "12px",
                "&.MuiButtonBase-root": {
                  width: "12px",
                  height: "12px",
                  padding: 0
                }
              }}>
              <InfoOutlinedIcon color="primary" size="small" />
            </IconButton>
          )}
          {showPopover && (
            <Popover
              id={popoverId}
              open={showPopover}
              anchorEl={showPopoverAnchor}
              onClose={() => handleTooltipClose(setShowPopoverAnchor)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              sx={{
                left: "20px"
              }}>
              <RichTooltip
                style={{ width: "300px" }}
                title={popoverTitle}
                bodyHtml={
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: popoverBody,
                      defaultMessage:
                        "By excluding these students, all graphs and charts on the analytics page will be updated."
                    })}
                  </Typography>
                }></RichTooltip>
            </Popover>
          )}
        </TableCell>
        {!showOnlyTitle && <TableCell>Assignment submissions</TableCell>}
        {!showOnlyTitle && (
          <TableCell sx={{ display: "flex", alignItems: "center" }}>
            Engagement
            <RichTooltipPopover
              name="Users"
              body={`analytics.tooltips.courseActivityReport.${CHART_NAMES.USERS}.ENGAGEMENT`}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

const TableBodyComponent = ({
  sortedUsers,
  currentUsers,
  indexOfFirstUser,
  indexOfLastUser,
  users,
  userBasedSubmissions,
  submissions,
  dispatchUsers,
  isSelected,
  usersBySubmissionStatus,
  timeSpentOnAssignmentData,
  maxPagePerTextData,
  setStudentsAtRiskIds,
  studentsAtRiskIds,
  theme,
  isFirstAssignmentPassed
}) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(null);
  const { course_id } = useQuery();
  const dispatch = useDispatch();

  const handleMouseEnter = useCallback(
    (userId) => {
      setHovered(userId);
    },
    [setHovered]
  );

  const handleMouseLeave = useCallback(() => {
    setHovered(null);
  }, [setHovered]);

  const activeUsers = currentUsers
    .filter((u) => users?.[u]?.active)
    .slice(indexOfFirstUser, indexOfLastUser);

  if (
    activeUsers.filter((u) => users?.[u]?.selected === isSelected).length === 0
  ) {
    return (
      <TableBody>
        <TableRow sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          <TableCell sx={{ minWidth: 220, width: "calc((100% - 200px) / 2)" }}>
            <Typography
              sx={{ color: theme.palette.text.secondary }}
              variant="body2">
              No users to show
            </Typography>
          </TableCell>
          <TableCell sx={{ width: "calc((100% - 200px) / 2)" }}></TableCell>
          <TableCell sx={{ width: "200px" }}></TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {isEmpty(sortedUsers) || isEmpty(maxPagePerTextData)
        ? fill(Array(3)).map((placeholder, i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton variant="circular" width={20} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))
        : activeUsers.map((userId, i) => {
            const user = users[userId];
            const icon = isSelected ? (
              <PersonRemoveAlt1Icon fontSize="small" />
            ) : (
              <PersonAddAlt1Icon fontSize="small" />
            );
            const isStudentAtRisk = studentsAtRiskIds.find(
              (student) => student.user_id === userId
            );
            const studentMsg = isSelected
              ? "Exclude student from data"
              : "Include student in data";

            return (
              user.selected === isSelected && (
                <React.Fragment key={user.course_user}>
                  <TableRow
                    onMouseEnter={() => handleMouseEnter(userId)}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                      borderBottom: isStudentAtRisk
                        ? "none"
                        : `1px solid ${theme.palette.divider}`
                    }}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        minWidth: 250,
                        width: "calc((100% - 200px) / 2)",
                        height: isStudentAtRisk ? 50 : "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        borderBottom: "none",
                        paddingBottom: isStudentAtRisk ? 0 : undefined // Add this line
                      }}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                          {indexOfFirstUser + i + 1}
                          <Link
                            onClick={() => {
                              dispatch(
                                setPersistent({
                                  courseAnalyticsLastLocation: {
                                    returnToLastLocation: true
                                  }
                                })
                              );
                              navigate(
                                `/reports/student?course_id=${course_id}&user_id=${userId}`
                              );
                            }}
                            aria-label={`${user.name} student analytics`}
                            underline={"hover"}
                            color={"inherit"}>
                            <span style={{ marginInlineStart: "8px" }}>
                              {truncate(`${user.name}`, { length: 20 })}
                            </span>
                          </Link>
                          {hovered === userId && (
                            <IconButton
                              size="small"
                              sx={{
                                padding: 0,
                                marginInlineStart: "8px",
                                width: "20px",
                                height: "20px"
                              }}
                              onClick={() =>
                                dispatchUsers({
                                  type: "toggle",
                                  payload: { user: userId }
                                })
                              }
                              aria-label="Toggle user">
                              <TooltipWithIntl
                                intlStringId={"analytics.exclude.user.msg"}
                                defaultMessage={studentMsg}
                                placement={"top"}>
                                {icon}
                              </TooltipWithIntl>
                            </IconButton>
                          )}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "none",
                        width: "fit-content"
                      }}>
                      <AssignmentSubmissions
                        rawSubmissions={userBasedSubmissions[userId]}
                      />
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none", width: "200px" }}>
                      {isFirstAssignmentPassed && (
                        <StudentEngagementChip
                          usersBySubmissionStatus={usersBySubmissionStatus.filter(
                            (entry) => entry.id === userId
                          )}
                          timeSpentOnAssignmentData={timeSpentOnAssignmentData}
                          userId={userId}
                          maxPagePerTextData={maxPagePerTextData}
                          rawSubmissions={submissions.filter(
                            (entry) => entry.owner === userId
                          )}
                          setStudentsAtRiskIds={setStudentsAtRiskIds}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  {isStudentAtRisk && (
                    <TableRow
                      sx={{
                        borderTop: "none"
                      }}>
                      <TableCell
                        colSpan={3}
                        sx={{
                          borderTop: "none",
                          borderBottom: `1px solid ${theme.palette.divider}`,
                          paddingTop: 0,
                          paddingBottom: 1 // Adjust this value as needed
                        }}>
                        <Typography
                          variant="caption"
                          sx={{ display: "block", width: "100%" }}>
                          {isStudentAtRisk.percentage.toFixed(0)}% of
                          submissions are missed
                          {isStudentAtRisk?.warning &&
                            isStudentAtRisk.percentage &&
                            " | "}
                          {isStudentAtRisk?.warning &&
                            `${isStudentAtRisk.warning}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )
            );
          })}
    </TableBody>
  );
};

const InactiveUsersTableBodyComponent = ({ users, theme }) => {
  function findInactiveUsers(users) {
    // Filter users where active is false
    const inactiveUsers = Object.values(users).filter(
      (user) => !user.active && user.course_role === "Student"
    );

    // Return array of inactive users with relevant information
    return inactiveUsers.map((user) => ({
      name: user.name,
      role: user.course_role,
      id: user.id,
      userId: user.course_user
    }));
  }
  const inactive = findInactiveUsers(users);

  if (inactive.length === 0) {
    return (
      <TableBody>
        <TableRow sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          <TableCell sx={{ minWidth: 220, width: "calc((100% - 200px) / 2)" }}>
            <Typography
              sx={{ color: theme.palette.text.secondary }}
              variant="body2">
              No users to show
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {inactive.map((user, i) => {
        return (
          <TableRow
            key={user.userId}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`
            }}>
            <TableCell component="th" scope="row" sx={{ borderBottom: "none" }}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  {`${i + 1} ${truncate(user.name, { length: 20 })}`}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const StudentEngagementReport = ({
  users,
  dispatchUsers,
  start,
  end,
  timeSpentOnAssignmentData,
  userBasedSubmissions,
  maxPagePerTextData,
  activeUsers,
  isFirstAssignmentPassed
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { course_id } = useQuery();
  const theme = useGetTheme();
  const intl = useIntl();
  const usersTableRef = useRef(null);
  // Ephemeral state
  const [submissions, setSubmissions] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [usersBySubmissionStatus, setUsersBySubmissionStatus] = useState([]);
  const [studentsAtRiskIds, setStudentsAtRiskIds] = useState([]);
  const [showExcludedUsersPopoverAnchor, setShowExcludedUsersPopoverAnchor] =
    useState(null);
  const showExcludedUsersPopover = Boolean(showExcludedUsersPopoverAnchor);
  const [showInactiveUsersPopoverAnchor, setShowInactiveUsersPopoverAnchor] =
    useState(null);
  const persistent = useSelector((state) => state.user.persistent);
  const showInactiveUsersPopover = Boolean(showInactiveUsersPopoverAnchor);
  const activeUsersLength = currentUsers.filter(
    (u) => users?.[u]?.active
  )?.length;

  useEffect(() => {
    if (Object.keys(users).length) {
      Object.values(users).forEach((u) => {
        u.active = activeUsers.includes(u.course_user);
      });
    }
  }, [users, activeUsers]);

  // Derived state
  const sortedUsers = useMemo(() => {
    return Object.values(users)
      .filter((user) => user.course_role === "Student")
      .map((user) => user.course_user);
  }, [users]);

  useEffect(() => {
    const isReturning =
      persistent["courseAnalyticsLastLocation"]?.returnToLastLocation || false;
    if (isReturning && usersTableRef.current) {
      usersTableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      dispatch(
        setPersistent({
          courseAnalyticsLastLocation: {
            returnToLastLocation: false
          }
        })
      );
    }
  }, [currentUsers]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;

  useEffect(() => {
    if (!end || !start || !course_id) return;
    httpCallables
      .readTasksAndSubmissionsInRange({
        course_id: Number(course_id),
        start: systemTimezoneToUtc(start),
        end: systemTimezoneToUtc(end)
      })
      .then(({ data }) => {
        if (data.success) {
          const { submissions } = JSON.parse(data.payload);
          setSubmissions(submissions);
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbar({
              message: intl.formatMessage({
                id: "error.readCourseUsersFailed",
                defaultMessage:
                  "There was a problem getting the course information. Please check your connection and try again"
              })
            })
          );
          captureException(error, `Failed to get course tasks and submissions`);
        }
      });
  }, [course_id, dispatch, end, start]);

  useEffect(() => {
    const sortedUsersBySubmissionStatus = sortUsersBySubmissionStatus(
      sortedUsers,
      submissions
    );
    setUsersBySubmissionStatus(sortedUsersBySubmissionStatus);
    const sortedCurrentUsersIdsBySubmissions =
      sortCurrentUsersIdsBySubmissionStatus(
        sortedUsers,
        sortedUsersBySubmissionStatus
      );
    setCurrentUsers(sortedCurrentUsersIdsBySubmissions);
  }, [sortedUsers, submissions]);

  const handleTooltipClose = (setAnchor) => {
    setAnchor(null);
  };

  const handleTooltipClick = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };

  return (
    <Box>
      <TableContainer>
        <Table aria-label="active users table" ref={usersTableRef}>
          <TableHeadComponent title="Active students" />
          <TableBodyComponent
            sortedUsers={sortedUsers}
            currentUsers={currentUsers}
            indexOfFirstUser={indexOfFirstUser}
            indexOfLastUser={indexOfLastUser}
            users={users}
            userBasedSubmissions={userBasedSubmissions}
            submissions={submissions}
            dispatchUsers={dispatchUsers}
            isSelected={true}
            usersBySubmissionStatus={usersBySubmissionStatus}
            timeSpentOnAssignmentData={timeSpentOnAssignmentData}
            maxPagePerTextData={maxPagePerTextData}
            setStudentsAtRiskIds={setStudentsAtRiskIds}
            studentsAtRiskIds={studentsAtRiskIds}
            theme={theme}
            isFirstAssignmentPassed={isFirstAssignmentPassed}
          />
        </Table>
      </TableContainer>
      <Box sx={{ marginTop: "32px" }}>
        <TableContainer>
          <Table aria-label="excluded users table">
            <TableHeadComponent
              title="Excluded students"
              handleTooltipClose={handleTooltipClose}
              handleTooltipClick={handleTooltipClick}
              intl={intl}
              showPopover={showExcludedUsersPopover}
              showPopoverAnchor={showExcludedUsersPopoverAnchor}
              setShowPopoverAnchor={setShowExcludedUsersPopoverAnchor}
            />
            <TableBodyComponent
              sortedUsers={sortedUsers}
              currentUsers={currentUsers}
              indexOfFirstUser={indexOfFirstUser}
              indexOfLastUser={indexOfLastUser}
              users={users}
              userBasedSubmissions={userBasedSubmissions}
              submissions={submissions}
              dispatchUsers={dispatchUsers}
              isSelected={false}
              usersBySubmissionStatus={usersBySubmissionStatus}
              timeSpentOnAssignmentData={timeSpentOnAssignmentData}
              maxPagePerTextData={maxPagePerTextData}
              setStudentsAtRiskIds={setStudentsAtRiskIds}
              studentsAtRiskIds={studentsAtRiskIds}
              theme={theme}
              isFirstAssignmentPassed={isFirstAssignmentPassed}
            />
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ marginTop: "32px" }}>
        <TableContainer>
          <Table aria-label="inactive users table">
            <TableHeadComponent
              title="Inactive students"
              handleTooltipClose={handleTooltipClose}
              handleTooltipClick={handleTooltipClick}
              intl={intl}
              showPopover={showInactiveUsersPopover}
              showPopoverAnchor={showInactiveUsersPopoverAnchor}
              setShowPopoverAnchor={setShowInactiveUsersPopoverAnchor}
              showOnlyTitle={true}
            />
            <InactiveUsersTableBodyComponent users={users} theme={theme} />
          </Table>
        </TableContainer>
      </Box>
      <TablePaginationFooter
        currentPage={currentPage}
        indexOfFirstUser={indexOfFirstUser}
        indexOfLastUser={indexOfLastUser}
        sortedUsers={sortedUsers}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        totalUsers={activeUsersLength}
      />
    </Box>
  );
};

export default StudentEngagementReport;
