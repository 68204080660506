import {
  parseISO,
  format,
  millisecondsToMinutes,
  isAfter,
  isBefore
} from "date-fns";
import { getTaskChartsDataFromApiResponse } from "../../admin/reports/courseActivity/transformUtils";
import { CHARTS_VIEW_TYPE } from "../../admin/reports/consts";
import { httpCallables } from "../../../firebase";

export const SESSION_TYPES = [
  "HIGHLIGHT_SESSION",
  "REVIEW_SESSION",
  "ANSWER_SESSION"
];
export const SESSION_TIMES = ["HIGHLIGHT_TIME", "REVIEW_TIME", "ANSWER_TIME"];

const SESSION_TIME_TYPES_MAP = {
  HIGHLIGHT_TIME: "HIGHLIGHT_SESSION",
  REVIEW_TIME: "REVIEW_SESSION",
  ANSWER_TIME: "ANSWER_SESSION"
};

const calculateSubmissionRate = (rawSubmissions) => {
  if (!rawSubmissions?.length) return 0;

  const today = new Date();

  // Filter submissions with due dates before today
  const pastDueDateSubmissions = rawSubmissions.filter((submission) => {
    const dueDate = new Date(submission.due_date);
    return isBefore(dueDate, today);
  });

  const submissions = pastDueDateSubmissions.reduce(
    (acc, submission) => {
      if (!submission.submission_date || !submission.due_date) return acc;

      const submissionDate = new Date(submission.submission_date);
      const dueDate = new Date(submission.due_date);

      // Only count submissions that were submitted before today
      if (isBefore(submissionDate, today)) {
        acc.total++;
        if (submissionDate <= dueDate) {
          acc.onTime++;
        }
      }

      return acc;
    },
    { total: 0, onTime: 0 }
  );

  // Calculate the rate as a percentage
  const submissionRate =
    submissions.total > 0
      ? (submissions.total / pastDueDateSubmissions.length) * 100
      : 0;

  return Math.round(submissionRate);
};
export const processSubmissionsStatus = (SUBMISSIONS_STATUS) => {
  // Deep copy of the original object to avoid mutation
  let result = JSON.parse(JSON.stringify(SUBMISSIONS_STATUS));
  let totalSubmissions = result.totalSubmissions;
  delete result.totalSubmissions;

  const processTask = (task) => {
    const processedTask = {};
    Object.keys(task).forEach((status) => {
      const currentStatus = task[status];

      if (status === "SubmissionPending" || status === "SubmissionMissed") {
        processedTask[status] = {
          total:
            currentStatus.complete +
            currentStatus.incomplete +
            (currentStatus.no_activity || 0)
        };
      } else {
        processedTask[status] = {
          complete: currentStatus.complete,
          incomplete:
            currentStatus.incomplete + (currentStatus.no_activity || 0)
        };
      }
    });
    return processedTask;
  };
  const updatedResult = {
    statusBased: processTask(result.statusBased),
    userBased: result.userBased
  };

  updatedResult.submissionRate =
    calculateSubmissionRate(result.rawSubmissions) || 0;
  updatedResult.totalSubmissions = totalSubmissions;
  return updatedResult;
};

export function flattenObject(obj, parentKey = "", separator = "_") {
  let flattened = {};

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let newKey = parentKey ? parentKey + separator + key : key;
      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key], newKey, separator));
      } else {
        flattened[newKey] = obj[key];
      }
    }
  }

  return flattened;
}

export const sortSubmissionsObject = (data) => {
  if (!data) return;
  const sortOrder = {
    SubmissionOnTime: 0,
    SubmissionLate: 1,
    SubmissionMissed: 2,
    SubmissionPending: 3
  };

  const dataArray = Object.entries(data);

  // If a key is not found in sortOrder, it will get a default value of Infinity, placing it at the end
  dataArray.sort(
    (a, b) =>
      (sortOrder[a[0]] !== undefined ? sortOrder[a[0]] : Infinity) -
      (sortOrder[b[0]] !== undefined ? sortOrder[b[0]] : Infinity)
  );

  const sortedData = {};
  dataArray.forEach(([key, value]) => {
    sortedData[key] = value;
  });

  return sortedData;
};

export const sortTimeSpentOnAssignmentObject = (data) => {
  if (!data) return;

  const sortOrder = {
    HIGHLIGHT_SESSION: 0,
    REVIEW_SESSION: 1,
    ANSWER_SESSION: 2
  };

  const dataArray = Object.entries(data);

  // If a key is not found in sortOrder, it will get a default value of Infinity, placing it at the end
  dataArray.sort(
    (a, b) =>
      (sortOrder[a[0]] !== undefined ? sortOrder[a[0]] : Infinity) -
      (sortOrder[b[0]] !== undefined ? sortOrder[b[0]] : Infinity)
  );

  const sortedData = {};
  dataArray.forEach(([key, value]) => {
    sortedData[key] = value;
  });

  return sortedData;
};

export function formatLegendLabel(label) {
  if (label.includes("Submission")) {
    // Remove 'Submission' and process the rest
    const restOfLabel = label.replace("Submission", "");

    if (restOfLabel.includes("Missed")) {
      return "Missed";
    } else if (restOfLabel.includes("Pending")) {
      return "Pending";
    } else {
      const prefix = restOfLabel.includes("OnTime") ? "On Time" : "Late";

      if (restOfLabel.includes("incomplete")) {
        return `${prefix} incomplete`;
      } else if (restOfLabel.includes("complete")) {
        return prefix;
      }

      return restOfLabel.replace(/_/g, " ").trim();
    }
  }
  return label;
}

export function calculateSubmissionPercentage(data, totalSubmissions) {
  return `${Math.floor((Number(data) / totalSubmissions) * 100)}%`;
}

export const transformSubmissionsData = (data, theme) => {
  const transformedData = data.map((item) => {
    return {
      cat: item[0],
      val: item[1],
      color: theme.palette.pieChart[item[0]] || "#E0E0E0"
    };
  });

  return transformedData;
};

export const removeFutureAssignments = (
  obj,
  isFirstAssignmentPassed = true
) => {
  let totalPending = 0;
  // Helper function to recursively traverse and copy the object
  function traverseAndCopy(obj) {
    let newObj = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (key === "SubmissionPending" && obj[key].total) {
          totalPending += obj[key].total;
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          newObj[key] = traverseAndCopy(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }

    return newObj;
  }

  function removeSubmissionPending(obj) {
    let newObj = {};
    for (let key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        key !== "SubmissionPending"
      ) {
        newObj[key] = traverseAndCopy(obj[key]);
      }
    }
    return newObj;
  }

  const cleanedData = isFirstAssignmentPassed
    ? removeSubmissionPending(obj)
    : {};

  return { cleanedData, totalPending: totalPending }; // because it sums for both taskBased & statusBased
};

export const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = parseISO(dateString);
  return format(date, "dd/MM/yyyy");
};

function calculatePercentages(data) {
  const result = {};

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const entry = data[key];
      const total = entry.total;

      // Initialize a new entry with the same id and total
      result[key] = { id: entry.id, total: total };

      // Calculate percentages for each key-value pair except id and total
      for (const prop in entry) {
        if (
          Object.prototype.hasOwnProperty.call(entry, prop) &&
          prop !== "id" &&
          prop !== "total"
        ) {
          result[key][prop] = ((entry[prop] / total) * 100).toFixed(0);
        }
      }
    }
  }

  return result;
}

export const processStackedGraphDataBySubmissionStatus = (data) => {
  const keys = [
    "SubmissionMissed_total",
    "SubmissionLate_complete",
    "SubmissionLate_incomplete",
    "SubmissionOnTime_complete",
    "SubmissionOnTime_incomplete",
    "SubmissionPending_total"
  ];
  let formattedData = {};

  for (const [key, value] of Object.entries(data)) {
    const [_userId, id, category, prefix, subcategory] = key.split("_");
    if (!formattedData[id]) {
      formattedData[id] = {
        id,
        total: 0,
        SubmissionMissed_total: 0,
        SubmissionLate_complete: 0,
        SubmissionLate_incomplete: 0,
        SubmissionOnTime_complete: 0,
        SubmissionOnTime_incomplete: 0,
        SubmissionPending_total: 0
      };
    }
    const fullKey =
      subcategory || category === "SubmissionMissed"
        ? `${category}_total`
        : `${category}_${prefix}`;
    formattedData[id].total += Number(value);

    formattedData[id][fullKey] += Number(value);
  }
  formattedData = calculatePercentages(formattedData);
  return { keys, data: Object.values(formattedData) };
};

export const processStackedGraphDataByAssignmentStepTime = (data) => {
  const keys = [
    "HIGHLIGHT_SESSION_total",
    "REVIEW_SESSION_total",
    "ANSWER_SESSION_total"
  ];

  let formattedData = {};

  for (const [key, value] of Object.entries(data)) {
    const [id, sessionType, session] = key.split("_");

    if (!formattedData[id]) {
      formattedData[id] = {
        id,
        total: 0,
        REVIEW_SESSION_total: 0,
        HIGHLIGHT_SESSION_total: 0,
        ANSWER_SESSION_total: 0
      };
    }

    formattedData[id][`${sessionType}_${session}_total`] +=
      millisecondsToMinutes(Number(value));
    formattedData[id].total += Number(value);
  }

  return { keys, data: Object.values(formattedData) };
};

// Function to format time duration in minutes
export function formatMillisecondsTime(data, courseAssignments = null) {
  let durationInMinutes;

  if (courseAssignments?.length) {
    durationInMinutes = millisecondsToMinutes(data / courseAssignments.length);
  } else if (typeof courseAssignments === "number") {
    durationInMinutes = millisecondsToMinutes(data) / courseAssignments;
  } else {
    durationInMinutes = millisecondsToMinutes(data);
  }

  // Round down to the nearest whole number of minutes
  const roundedMinutes = Math.round(durationInMinutes);
  // Return the formatted time in minutes
  return `${roundedMinutes} min`;
}

export const truncateMiddle = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const ellipsis = "...";

  // Split the text into words
  const words = text.split(" ");

  // Initialize variables to track the length of the truncated text
  let truncatedText = "";
  let currentLength = 0;

  // Iterate through the words
  for (const word of words) {
    // Check if adding the current word exceeds the maxLength
    if (currentLength + word.length + ellipsis.length <= maxLength) {
      truncatedText += `${word} `;
      currentLength += word.length + 1; // Add 1 for the space
    } else {
      // Stop if adding the current word exceeds the maxLength
      break;
    }
  }

  // Trim the trailing space and add the ellipsis
  truncatedText = truncatedText.trim() + ellipsis;

  return truncatedText;
};

export function normalizeGrade(grade) {
  if (typeof grade === "string") {
    // Normalize from A-F scale
    grade = grade.toUpperCase();
    const letterGrades = {
      "A+": 100,
      A: 95,
      "A-": 90,
      "B+": 87,
      B: 85,
      "B-": 80,
      "C+": 77,
      C: 75,
      "C-": 70,
      "D+": 67,
      D: 65,
      "D-": 60,
      F: 50
    };
    return letterGrades[grade] !== undefined ? letterGrades[grade] : 0;
  } else if (typeof grade === "number") {
    if (grade >= 0 && grade <= 10) {
      // Normalize from 1-10 scale to 1-100 scale
      return (grade / 10) * 100;
    } else if (grade >= 0 && grade <= 100) {
      // Already in the correct scale
      return grade;
    } else {
      // For any other number, assume it's in a 0-100 scale but needs to be clamped
      return Math.min(Math.max(grade, 0), 100);
    }
  }
  // If the grade type is unrecognized, return 0
  return 0;
}

export function reverseNormalizeGrade(grade, maxGrade) {
  if (typeof grade === "string") {
    // Reverse normalize from A-F scale
    const letterGrades = {
      100: "A+",
      95: "A",
      90: "A-",
      87: "B+",
      85: "B",
      80: "B-",
      77: "C+",
      75: "C",
      70: "C-",
      67: "D+",
      65: "D",
      60: "D-",
      50: "F"
    };
    return letterGrades[normalizeGrade(grade)] !== undefined ? grade : "F";
  } else if (typeof grade === "number") {
    if (maxGrade === 100) {
      // Reverse normalize from 0-100 scale to 0-10 scale
      return grade;
    } else if (maxGrade < 100 && maxGrade !== 0) {
      // Already in the correct scale
      return (grade / maxGrade) * 100;
    }
  }
  // If the grade type is unrecognized, return 0
  return 0;
}

export const sumTaskById = (data) => {
  let accumulatedTasks = {};

  Object.values(data).forEach((userTasks) => {
    Object.entries(userTasks).forEach(([taskId, taskStatuses]) => {
      if (!accumulatedTasks[taskId]) {
        accumulatedTasks[taskId] = {
          SubmissionMissed: 0,
          SubmissionOnTime_complete: 0,
          SubmissionOnTime_incomplete: 0,
          SubmissionPending: 0,
          SubmissionLate_complete: 0,
          SubmissionLate_incomplete: 0
        };
      }

      Object.entries(taskStatuses).forEach(([statusType, statusCounts]) => {
        if (
          statusType === "SubmissionMissed" ||
          statusType === "SubmissionPending"
        ) {
          accumulatedTasks[taskId][statusType] +=
            statusCounts.no_activity ||
            statusCounts.complete ||
            statusCounts.incomplete;
        } else if (
          statusType === "SubmissionOnTime" ||
          statusType === "SubmissionLate"
        ) {
          accumulatedTasks[taskId][`${statusType}_complete`] +=
            statusCounts.complete || 0;
          accumulatedTasks[taskId][`${statusType}_incomplete`] +=
            statusCounts.incomplete || 0;
        }
      });
    });
  });

  return accumulatedTasks;
};

export function getSessionAveragesPerTaskAndStudent(data) {
  function averageTimeData(data) {
    const count = Object.keys(data).length;

    return Object.values(data).reduce((avg, item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (!avg[key]) {
          avg[key] = { total: 0 };
        }

        avg[key].total = (avg[key].total || 0) + value.average / count;
      });
      return avg;
    }, {});
  }

  function initSessionData() {
    return {
      REVIEW_SESSION: { total: 0, userIds: new Set() },
      HIGHLIGHT_SESSION: { total: 0, userIds: new Set() },
      ANSWER_SESSION: { total: 0, userIds: new Set() }
    };
  }
  const tasksTimeSpend = {};

  function addToTotal(aggregator, sessionType, duration, userId) {
    aggregator[sessionType].total += duration;
    aggregator[sessionType].userIds.add(userId);
  }

  function handleSessionItem(item, sessionType) {
    if (!tasksTimeSpend[item.task_id]) {
      tasksTimeSpend[item.task_id] = initSessionData();
    }
    addToTotal(
      tasksTimeSpend[item.task_id],
      SESSION_TIME_TYPES_MAP[sessionType],
      item.duration,
      item.user_id
    );
  }

  SESSION_TIMES.forEach((sessionType) => {
    data[sessionType].forEach((item) => {
      handleSessionItem(item, sessionType);
    });
  });
  for (let taskId in tasksTimeSpend) {
    let userIds = new Set();
    const taskData = tasksTimeSpend[taskId];

    SESSION_TYPES.forEach((sessionType) => {
      userIds = new Set([...userIds, ...taskData[sessionType].userIds]);
    });

    SESSION_TYPES.forEach((sessionType) => {
      taskData[sessionType].average =
        taskData[sessionType].total / userIds.size;
    });
  }

  const aggregated = averageTimeData(tasksTimeSpend);
  return [aggregated, tasksTimeSpend];
}

export function extractAssignmentNumberFromString(label) {
  // Use a regular expression to match the number at the beginning of the string
  const match = label.match(/^\d+/);
  // If a match is found, return it as a number
  return match ? parseInt(match[0], 10) : null;
}

// user submissions utils
export function getSubmissionStatus(submission) {
  // takes submission obj and return an obj with user uid and submission status as string
  return {
    id: submission.owner,
    status: calculateSubmissionStatus(submission)
  };
}

export function calculateSubmissionStatus(submission) {
  // takses a submission obj and return the status a string
  const submissionsDate = submission.submission_date;
  const today = new Date();
  const dueDate = new Date(submission.due_date);
  const submissionDate = submissionsDate ? new Date(submissionsDate) : null;
  const isSubmitted = Boolean(submissionDate);

  // submitted before the due date
  if (isSubmitted && isBefore(submissionDate, dueDate)) return "submitted";
  // submitted after the due date
  else if (isSubmitted && isAfter(submissionDate, dueDate)) return "late";
  // not submitted and due date passed
  else if (!isSubmitted && isAfter(today, dueDate)) return "missed";
  // not submitted and due date not passed
  else return "pending";
}

export function unifyIncompleteToComplete(obj) {
  function processObject(target) {
    if (typeof target === "object" && target !== null) {
      for (const key in target) {
        if (typeof target[key] === "object" && target[key] !== null) {
          processObject(target[key]);
        }
        if (key === "complete" && "incomplete" in target) {
          target[key] += target.incomplete;
          delete target.incomplete;
        }
      }
    }
  }

  const result = JSON.parse(JSON.stringify(obj)); // Deep copy the object
  processObject(result);
  return result;
}

// Consolidation

export const generateCourseStudentsList = (users) => {
  if (Array.isArray(users)) {
    return users.filter((user) => user.course_role === "Student");
  } else if (typeof users === "object" && users !== null) {
    return Object.values(users).filter(
      (user) => user.course_role === "Student"
    );
  }
  return [];
};

const initializeUsersState = (users) => {
  return generateInitialUsersStateReducer(users);
};

// utils.js
export const generateInitialUsersStateReducer = (users) => {
  if (Object.keys(users).length === 0) {
    return {};
  }

  // Check if users is an array or an object
  if (Array.isArray(users)) {
    // Handle array input
    return users.reduce((accumulator, current) => {
      const user = current.course_user;
      const selected = true;
      return {
        ...accumulator,
        [user]: { ...current, selected }
      };
    }, {});
  } else {
    // Handle object input
    return Object.entries(users).reduce((accumulator, [key, current]) => {
      return {
        ...accumulator,
        [key]: { ...current, selected: true }
      };
    }, {});
  }
};

export function usersReducer(state, action) {
  switch (action.type) {
    case "set":
      return { ...action.payload };
    case "toggle": {
      let { user } = action.payload;
      return {
        ...state,
        [user]: { ...state[user], selected: !state[user].selected }
      };
    }
    case "update": {
      let { user: userToUpdate, ...rest } = action.payload;
      return {
        ...state,
        [userToUpdate]: { ...state[userToUpdate], ...rest }
      };
    }
    default:
      throw new Error();
  }
}

export const handleApiResponse = (response, successCallback, errorCallback) => {
  if (response.data.success) {
    successCallback(response.data.payload);
  } else {
    errorCallback(response.data.error);
  }
};

export const fetchTaskData = (course_id, start, end) => {
  return httpCallables.adminGetStats({
    course_id: Number(course_id),
    start,
    end,
    type: CHARTS_VIEW_TYPE.TASK.toUpperCase()
  });
};

export const fetchWeekData = (course_id, start, end) => {
  return httpCallables.adminGetStats({
    course_id: Number(course_id),
    start,
    end,
    type: CHARTS_VIEW_TYPE.WEEK.toUpperCase()
  });
};

export const fetchAssignmentData = (task_id, course_id) => {
  return httpCallables.adminGetStats({
    task_id: Number(task_id),
    course_id: Number(course_id),
    type: "ASSIGNMENT"
  });
};

export const processTaskResponse = (taskResponse, setFunctions) => {
  const {
    setSubmissions,
    setRawSubmissions,
    setCourseAssignments,
    setTaskChartsData,
    setTimeSpentOnAssignmentData,
    setTaskIds,
    dispatchUsers,
    setActiveUsers
  } = setFunctions;

  // Check if taskResponse is valid
  if (!taskResponse || typeof taskResponse !== "object") {
    console.error("Invalid task response:", taskResponse);
    return;
  }

  // Process submissions
  if (taskResponse.SUBMISSIONS_STATUS) {
    const submissionsData = processSubmissionsStatus(
      taskResponse.SUBMISSIONS_STATUS
    );
    setSubmissions && setSubmissions(submissionsData);
    setRawSubmissions &&
      setRawSubmissions(taskResponse.SUBMISSIONS_STATUS.rawSubmissions);
  } else {
    console.warn("SUBMISSIONS_STATUS not found in task response");
  }

  // Process course assignments
  if (
    taskResponse.PUBLISHED_TASKS &&
    Array.isArray(taskResponse.PUBLISHED_TASKS.tasks)
  ) {
    setCourseAssignments(taskResponse.PUBLISHED_TASKS.tasks);

    if (setTaskIds) {
      const taskIds = taskResponse.PUBLISHED_TASKS.tasks.map((task) => task.id);
      setTaskIds(taskIds);
    }
  } else {
    console.warn("PUBLISHED_TASKS not found or invalid in task response");
  }

  // Process task charts data
  setTaskChartsData(getTaskChartsDataFromApiResponse(taskResponse));

  // Process time spent on assignment data
  const timePerStep = {
    HIGHLIGHT_TIME: taskResponse.HIGHLIGHT_TIME,
    REVIEW_TIME: taskResponse.REVIEW_TIME,
    ANSWER_TIME: taskResponse.ANSWER_TIME
  };
  setTimeSpentOnAssignmentData(timePerStep);

  // Process user data
  if (taskResponse.COURSE_USER_NAME) {
    const initializedUsers = initializeUsersState(
      taskResponse.COURSE_USER_NAME
    );
    dispatchUsers({
      type: "set",
      payload: initializedUsers
    });
  } else {
    console.warn("COURSE_USER_NAME not found in task response");
    dispatchUsers({
      type: "set",
      payload: {}
    });
  }
  if (setActiveUsers && taskResponse.ACTIVE_COURSE_USERS) {
    setActiveUsers(taskResponse.ACTIVE_COURSE_USERS);
  }
};

export function findMatchingTaskNameByEntry(weekString, tasksArray) {
  // Extract the id from the 'week' string by removing the 'A' prefix
  const taskId = parseInt(weekString.substring(1));

  // Find the corresponding task in the tasksArray
  const matchedTask = tasksArray.find((task) => task.id === taskId);

  // If a matching task is found, return it; otherwise, return null
  return matchedTask || null;
}

export function findTaskData(taskObject, taskArray) {
  // Extract taskId and prepend 'A' to match the format in the array
  const taskId = "A" + taskObject.taskId;

  // Find the matching object in the array
  const matchingTask = taskArray.find((item) => item.week === taskId);

  // If a match is found, return the requested data
  if (matchingTask) {
    return {
      task: matchingTask.task,
      gr: matchingTask.gr
    };
  }

  // If no match is found, return null or some default value
  return null;
}

export function extractMatchAndMergeProperties(sourceObj, targetObj) {
  const result = {};

  // Iterate over the keys in the source object
  for (const key in sourceObj) {
    // Extract the first word (assuming it's before an underscore)
    const firstWord = key.split("_")[0].toLowerCase();

    // Check if the extracted word exists as a property in the target object
    if (firstWord in targetObj) {
      // If it exists, merge the numeric value with the string
      result[firstWord] = `${sourceObj[key]}: ${targetObj[firstWord]}`;
    }
  }

  return result;
}

export const adjustPercentages = (items, divisor) => {
  if (!Array.isArray(items) || divisor === 0) return items;

  const result = items.map((item) => ({ ...item }));

  const values = items.map((item) => item.val);
  const exactPercentages = values.map((val) => (val / divisor) * 100);
  const roundedPercentages = exactPercentages.map((val) => Math.round(val));

  // Calculate the sum of rounded percentages
  const sum = roundedPercentages.reduce((acc, val) => acc + val, 0);

  if (sum === 100) {
    // If sum is already 100, just add the rounded percentages
    result.forEach((item, i) => {
      item.percentage = roundedPercentages[i];
    });
    return result;
  }

  // Calculate the differences between exact and rounded values
  const differences = exactPercentages.map((exact, i) => ({
    index: i,
    diff: exact - roundedPercentages[i],
    rounded: roundedPercentages[i]
  }));

  // Sort by decimal part in descending order
  differences.sort((a, b) => Math.abs(b.diff) - Math.abs(a.diff));

  // Copy rounded percentages as base values
  const adjustedPercentages = [...roundedPercentages];

  // Adjust values to reach 100%
  const adjustment = 100 - sum;

  if (adjustment > 0) {
    // Need to add to reach 100%
    for (let i = 0; i < adjustment; i++) {
      adjustedPercentages[differences[i]?.index]++;
    }
  } else if (adjustment < 0) {
    // Need to subtract to reach 100%
    for (let i = 0; i < Math.abs(adjustment); i++) {
      adjustedPercentages[differences[i]?.index]--;
    }
  }

  // Add adjusted percentages to result objects
  result.forEach((item, i) => {
    item.percentage = adjustedPercentages[i];
  });

  return result;
};
