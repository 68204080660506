import React from "react";
import { useGetTheme } from "../../../hooks";
import { Box, Typography } from "@mui/material";
import PieChart from "../../SharedComponents/reports/pieChart/PieChart";

const AssignmentEngagementTime = ({ data, totalSubmissions, activeUsers }) => {
  const theme = useGetTheme();
  return (
    <Box
      sx={{
        paddingInline: "24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "60px",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`,
          marginBottom: "32px"
        }}>
        <Typography variant="subtitle1">
          Average time spent on assignments step
        </Typography>
      </Box>
      <PieChart
        type="timeSpentOnAssignment"
        data={data}
        activeUsers={activeUsers}
        totalSubmissions={totalSubmissions}
      />
    </Box>
  );
};

export default AssignmentEngagementTime;
