// Dependencies
import React, { useEffect, useState } from "react";
import { useGetTheme, useQuery } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { httpCallables } from "../../../firebase";
import { captureException } from "../../../utils/errorHandlers";
import { useIntl } from "react-intl";
import { CHARTS_VIEW_TYPE } from "../../admin/reports/consts";
import { useNavigate } from "react-router";

// Redux
import { selectCourse } from "../../../redux/coursesSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { addSnackbar } from "../../../redux/snackbarSlice";

// Components
import ScrollBox from "../ScrollBox";
import CustomSkeleton from "../CustomSkeleton";
// Material-UI
import { Box, IconButton, Typography } from "@mui/material";
import StudentSubmissionsReport from "./StudentSubmissionsReport";
import StudentSubmissionsStatus from "./StudentSubmissionsStatus";
import StudentSubmissionsTime from "./StudentSubmissionsTime";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StudentAnalytics = () => {
  // Hooks
  const { course_id, user_id } = useQuery();
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useGetTheme();
  const navigate = useNavigate();

  // Redux
  const course = useSelector((state) => selectCourse(state, Number(course_id)));

  // Ephemeral state
  const [student, setStudent] = useState();
  const [studentSubmissions, setStudentSubmissions] = useState([{}]);
  const [courseAssignments, setCourseAssignments] = useState([]);
  const [submissionsStatus, setSubmissionsStatus] = useState([]);
  const [timeSpentOnAssignmentData, setTimeSpentOnAssignmentData] = useState(
    []
  );
  const [isEngagementLoading, setIsEngagementLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Derived state
  const courseStart = course.course_start;
  const courseEnd = course.course_end;
  // Behavior

  // get user
  useEffect(() => {
    httpCallables.getUser({ user_id }).then(({ data }) => {
      const { success } = data;
      if (success) {
        const parsedData = JSON.parse(data.payload);
        const { user } = parsedData;
        setStudent(user);
      }
    });
  }, []);

  // get user course submissions
  useEffect(() => {
    httpCallables
      .taskFunctions({
        func_name: "readTeacherCourseTasks",
        course_id: Number(course_id),
        courseStart,
        courseEnd
      })
      .then(({ data }) => {
        const { tasks, submissions } = JSON.parse(data.payload);
        setCourseAssignments(tasks);
        setStudentSubmissions(
          submissions.filter((submission) => submission.owner === user_id)
        );
      });
  }, []);

  // Set breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: course.name,
      url: `/tasks?course_id=${course_id}`
    });
    parts.push({
      url: `/reports/student?course_id=${course_id}&user_id=${user_id}`,
      text: intl.formatMessage({
        id: "analytics.studentAnalytics",
        defaultMessage: "Student analytics"
      })
    });
    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course_id, student, dispatch]);

  const filterTimeSpentForUserId = (timeSpent, userId) => {
    timeSpent.ANSWER_TIME = timeSpent.ANSWER_TIME.filter(
      (obj) => obj.user_id === userId
    );
    timeSpent.HIGHLIGHT_TIME = timeSpent.HIGHLIGHT_TIME.filter(
      (obj) => obj.user_id === userId
    );
    timeSpent.REVIEW_TIME = timeSpent.REVIEW_TIME.filter(
      (obj) => obj.user_id === userId
    );
  };

  useEffect(() => {
    if (studentSubmissions && courseAssignments.length && student) {
      setIsEngagementLoading(false);
    }
  }, [studentSubmissions, courseAssignments, student]);

  useEffect(() => {
    if (submissionsStatus && timeSpentOnAssignmentData) {
      setIsLoading(false);
    }
  }, [submissionsStatus, timeSpentOnAssignmentData]);

  // Get Course task data
  useEffect(() => {
    if (course) {
      setIsLoading(true);
      setIsEngagementLoading(true);

      const getTaskDataPromise = httpCallables.adminGetStats({
        course_id: Number(course_id),
        start: courseStart,
        end: courseEnd,
        type: CHARTS_VIEW_TYPE.TASK.toUpperCase()
      });

      Promise.all([getTaskDataPromise])
        .then(([taskResponse]) => {
          if (taskResponse.data.success) {
            setSubmissionsStatus(
              taskResponse.data.payload.SUBMISSIONS_STATUS.userBased[user_id]
            );
            const timePerStep = {
              HIGHLIGHT_TIME: taskResponse.data.payload.HIGHLIGHT_TIME,
              REVIEW_TIME: taskResponse.data.payload.REVIEW_TIME,
              ANSWER_TIME: taskResponse.data.payload.ANSWER_TIME
            };
            filterTimeSpentForUserId(timePerStep, user_id);
            setTimeSpentOnAssignmentData(timePerStep);
          } else {
            dispatch(
              addSnackbar({
                message: intl.formatMessage({
                  id: "error.readCourseUsersFailed",
                  defaultMessage:
                    "There was a problem getting the student information. Please check your connection and try again"
                })
              })
            );
            if (!taskResponse.data.success) {
              captureException(
                taskResponse.data.error,
                `Failed to get task Stats`
              );
            }
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the promises
          console.error("Error fetching data:", error);
        });
    }
  }, [courseStart, courseEnd, course_id, course, dispatch]);

  return (
    <ScrollBox>
      <Box
        sx={{
          width: "100%",
          overFlowY: "scroll",
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingInlineEnd: "16px",
          paddingBlockEnd: "80px",
          backgroundColor: theme.palette.background.default
        }}>
        <Box
          sx={{
            width: "950px",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingBlockStart: "22px"
          }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <IconButton
              aria-label="go-back"
              size="small"
              onClick={() => {
                navigate(-1);
              }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ alignContent: "center" }} variant="subtitle1">
              {student?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBlockStart: "32px"
            }}>
            <Typography variant="h6" sx={{ marginBlockEnd: "16px" }}>
              Student Engagement
            </Typography>
            {isEngagementLoading ? (
              <CustomSkeleton height={"200px"} />
            ) : (
              <StudentSubmissionsReport submissions={studentSubmissions} />
            )}
          </Box>
          <Box sx={{ width: "100%", marginBlockStart: "32px" }}>
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <StudentSubmissionsStatus
                submissions={studentSubmissions}
                assignments={courseAssignments}
                statuses={submissionsStatus}
              />
            )}
          </Box>
          <Box sx={{ width: "100%", marginBlockStart: "32px" }}>
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <StudentSubmissionsTime
                submissions={studentSubmissions}
                assignments={courseAssignments}
                timeSpent={timeSpentOnAssignmentData}
              />
            )}
          </Box>
        </Box>
      </Box>
    </ScrollBox>
  );
};

export default StudentAnalytics;
