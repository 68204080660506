import React, { useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// MUI Components
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  LinearProgress,
  Chip,
  Card
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Components
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";

// Redux Selectors
import {
  selectQuestionHighlights,
  selectQuestionAnswers
} from "../../../redux/interactionsSlice";
import { selectCurrentText } from "../../../redux/textsSlice";

// Constants
import { USER_TYPE } from "../../../consts";
import { normalize } from "./utils";

// Styles
const useStyles = makeStyles((theme) => ({
  statContainer: {
    padding: "32px"
  },
  multiChoiceContainer: {
    display: "flex",
    flexFlow: "row nowrap"
  },
  citationContainer: {
    flex: "0 0 50%",
    padding: "32px"
  },
  mainStatsContainer: {
    display: "flex",
    flex: "0 0 50%",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  },
  answerDistributionContainer: {
    padding: "32px"
  },
  questionOptionContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "36px"
  },
  chipContainer: {
    width: "200px",
    position: "relative"
  },
  chip: {
    position: "absolute",
    top: "5px"
  },
  optionContainer: {
    width: "100%"
  },
  optionText: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    fontSize: "20px"
  },
  statBar: {
    height: "8px",
    backgroundColor: "#D8D8D8",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#168FEE"
    }
  },
  actionButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    padding: "0px 8px",
    height: "40px"
  },
  questionSubtitle: {
    maxWidth: "600px"
  }
}));

const QuestionHeader = ({ index, question, intl }) => (
  <Box component="header" sx={{ paddingBlock: "32px" }}>
    <Typography variant="h6" style={{ marginBottom: "15px" }}>
      {intl.formatMessage({ id: "task.question", defaultMessage: "Question" })}{" "}
      #{index + 1} —{" "}
      {intl.formatMessage({
        id: "task.type.multi",
        defaultMessage: "Multiple choice"
      })}
    </Typography>
    <Typography
      className="questionSubtitle"
      variant="subtitle1"
      component="span">
      {question.content}
    </Typography>
  </Box>
);

const AnswerOption = ({
  option,
  optionIndex,
  isCorrect,
  percentage,
  classes,
  intl
}) => (
  <Box key={optionIndex} className={classes.questionOptionContainer}>
    <Box className={classes.chipContainer}>
      <Chip
        label={
          isCorrect
            ? intl.formatMessage({
                id: "task.statistics.correct",
                defaultMessage: "Correct"
              })
            : intl.formatMessage({
                id: "task.statistics.incorrect",
                defaultMessage: "Incorrect"
              })
        }
        variant={isCorrect ? "default" : "outlined"}
        style={
          isCorrect
            ? {
                backgroundColor: "#168FEE",
                color: "#FFFFFF"
              }
            : null
        }
        className={classes.chip}
      />
    </Box>

    <Box className={classes.optionContainer}>
      <Box className={classes.optionText}>
        <Typography variant="subtitle2">{option}</Typography>
        <Typography variant="subtitle2">{percentage}%</Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        aria-label="stats bar"
        value={percentage}
        className={classes.statBar}
      />
    </Box>
  </Box>
);

function MultiChoiceStats({
  submissions = [],
  question,
  index,
  task,
  isTeacher = true,
  submittedSubmissionsIds = []
}) {
  const classes = useStyles();
  const intl = useIntl();
  const [openHeatmap, setOpenHeatmap] = useState(false);

  // Redux selectors
  const questionHighlights = useSelector((state) =>
    selectQuestionHighlights(state, question.id)
  );
  const questionAnswers = useSelector((state) =>
    selectQuestionAnswers(state, question.id)
  );
  const text = useSelector(selectCurrentText);

  // Derived state using useMemo
  // Replace the distribution calculation in useMemo with this:
  const {
    withCitations,
    teacherHighlights,
    studentHighlights,
    answerDistribution
  } = useMemo(() => {
    const withCitations =
      question.quotes.length > 0 && !!question.includeCitation;
    const teacherHighlights = question.quotes || [];
    const studentHighlights = questionHighlights.filter(
      (highlight) => highlight.user_type === USER_TYPE.STUDENT
    );

    // Filter only MULTI_CHOICE answers
    const submittedAnswers = questionAnswers.filter(
      (answer) => answer.interaction_subtype === "MULTI_CHOICE"
    );

    // Initialize counters for each option
    const counts = {};
    question.options.forEach((_, index) => {
      counts[index] = 0;
    });

    // Count responses for each option
    submittedAnswers.forEach((answer) => {
      const choice = Number(answer.choice);
      if (choice in counts) {
        counts[choice] += 1;
      }
    });

    // Calculate total responses
    const totalResponses = submittedAnswers.length || 1; // Avoid division by zero

    // Calculate distribution with percentages
    const distribution = Object.entries(counts).map(([optionIndex, count]) => ({
      optionIndex: Number(optionIndex),
      count,
      percentage: Math.round((count / totalResponses) * 100)
    }));

    return {
      withCitations,
      teacherHighlights,
      studentHighlights,
      answerDistribution: distribution
    };
  }, [question, questionHighlights, questionAnswers]);

  const renderTeacherView = () => (
    <Paper component="section" elevation={0}>
      <HeatMap
        totalCount={submissions.filter((s) => s.submission_date).length}
        openDialog={openHeatmap}
        teacherHighlights={teacherHighlights}
        setOpenDialog={setOpenHeatmap}
        url={text.url}
        location={text.file_location}
        hlOpacity={1}
        hlColor="#4AA96C"
        highlights={studentHighlights}
        addHighlight={() => {}}
        removeHighlight={() => {}}
      />

      <QuestionHeader index={index} question={question} intl={intl} />

      <Box className={classes.multiChoiceContainer}>
        {withCitations && (
          <Box className={classes.citationContainer}>
            <CitationChart
              question={question}
              i={index}
              task={task}
              submissions={submissions}
              answers={studentHighlights}
            />
          </Box>
        )}

        <Box className={classes.mainStatsContainer}>
          <Box className={classes.answerDistributionContainer}>
            {question.options.map((option, optionIndex) => {
              const distributionData = answerDistribution.find(
                (d) => d.optionIndex === optionIndex
              );
              const isCorrect = Number(question.shouldSelect) === optionIndex;

              return (
                <AnswerOption
                  key={optionIndex}
                  option={option}
                  optionIndex={optionIndex}
                  isCorrect={isCorrect}
                  percentage={distributionData?.percentage || 0}
                  classes={classes}
                  intl={intl}
                />
              );
            })}
          </Box>

          {withCitations && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              disableElevation
              className={classes.actionButton}
              onClick={() => setOpenHeatmap(true)}>
              {intl.formatMessage({
                id: "task.statistics.showHeatmap",
                defaultMessage: "Show heatmap"
              })}
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  );

  const renderStudentView = () => (
    <Card component="section" elevation={1} className={classes.statContainer}>
      <HeatMap
        totalCount={submittedSubmissionsIds.length}
        openDialog={openHeatmap}
        teacherHighlights={teacherHighlights}
        setOpenDialog={setOpenHeatmap}
        url={text.url}
        location={text.file_location}
        hlOpacity={1}
        hlColor="#4AA96C"
        highlights={studentHighlights}
        addHighlight={() => {}}
        removeHighlight={() => {}}
      />

      <Box component="header">
        <Typography variant="h6" style={{ marginBottom: "15px" }}>
          {intl.formatMessage({
            id: "task.question",
            defaultMessage: "Question"
          })}{" "}
          #{index + 1} —{" "}
          {intl.formatMessage({
            id: "task.type.multi",
            defaultMessage: "Multiple choice"
          })}
        </Typography>
        <Divider />
        <Box
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            paddingTop: "15px"
          }}>
          <Typography className={classes.questionSubtitle} variant="subtitle1">
            {question.content}
          </Typography>
          {withCitations && (
            <Box>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disableElevation
                className={classes.actionButton}
                onClick={() => setOpenHeatmap(true)}>
                {intl.formatMessage({
                  id: "task.statistics.showHeatmap",
                  defaultMessage: "Show heatmap"
                })}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );

  return isTeacher ? renderTeacherView() : renderStudentView();
}

export default MultiChoiceStats;
MultiChoiceStats.propTypes = {
  submissions: PropTypes.array,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    includeCitation: PropTypes.bool,
    quotes: PropTypes.array,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    shouldSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool,
  submittedSubmissionsIds: PropTypes.array
};
