// Dependencies
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { httpCallables } from "../../../firebase";
import { useQuery } from "../../../hooks";
import { useNavigate } from "react-router";
import useUserClaim, { CLAIMS_ROLES } from "../../../hooks/useUserClaim";

//Redux Dependencies
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  setTasks,
  setSubmissions,
  selectTask,
  selectTaskSubmissions
} from "../../../redux/tasksSlice";
import {
  selectText,
  setSelectedTextId,
  setTextUrl,
  addText
} from "../../../redux/textsSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import {
  setInteractions,
  selectQuestions
} from "../../../redux/interactionsSlice";

// Components
import TeacherTaskStatsView from "./TeacherTaskStatsView";
import StudentTaskStatsView from "./StudentTaskStatsView";

import { USER_TYPE } from "../../../consts";

const TaskStatsView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { task_id } = useQuery();
  const navigate = useNavigate();

  // Redux State

  const course = useSelector((state) =>
    selectCourseByTaskId(state, Number(task_id))
  );
  const courseRole = course.course_role;
  const task = useSelector((state) => selectTask(state, task_id));
  const text = useSelector((state) => selectText(state, task.text_id));

  const submissions = useSelector((state) =>
    selectTaskSubmissions(state, task_id)
  );
  const interactions = useSelector((state) => state.interactions.answers);
  const isAdmin = useUserClaim(CLAIMS_ROLES.ADMIN);
  const questions = useSelector((state) => selectQuestions(state, task_id));
  const [submittedSubmissionsIds, setSubmittedSubmissionsIds] = useState([]);
  // Ephemeral State

  // Derived State
  // Ephemeral State

  // Derived State

  const isTeacher = courseRole === "Teacher";
  const isCourseAdmin = courseRole === "Admin";
  // Behavior
  useEffect(() => {
    if (!task_id) {
      return;
    }
    httpCallables
      .taskFunctions({
        func_name: "readTaskStats",
        task_id: Number(task_id)
      })
      .then(
        ({ data }) => {
          const { task, submissions, interactions, submittedSubmissionsIds } =
            JSON.parse(data);
          if (!task) {
            navigate("/tasks");
            return;
          }
          dispatch(setTasks([task]));
          dispatch(
            setSubmissions(
              submissions.filter((s) => {
                return s.role?.toUpperCase() !== USER_TYPE.TEACHER;
              })
            )
          );
          dispatch(setInteractions(interactions));
          setSubmittedSubmissionsIds(submittedSubmissionsIds);
          if (task.course_texts) {
            dispatch(addText(task.course_texts));
          }
        },
        [task_id]
      );
  }, [dispatch, task_id]);

  useEffect(() => {
    if (!text.id) return;
    dispatch(setSelectedTextId(text.id));
  }, [dispatch, text.id]);

  useEffect(() => {
    if (!text.id) return;
    httpCallables
      .textFunctions({
        func_name: "getTextDownloadUrl",
        courseId: text.course_id,
        fileUrl: text.file_url,
        textId: text.id
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setTextUrl({ url: response.data.payload, text_id: text.id })
          );
        }
      });
  }, [dispatch, text?.course_id, text?.file_url, text?.id]);

  //breadcrumbs effect
  useEffect(() => {
    let parts = [];
    parts.push({
      url: "/tasks",
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      })
    });
    course.name &&
      parts.push({ url: `/tasks?course_id=${course.id}`, text: course.name });

    task.name &&
      parts.push({
        url: `/tasks?course_id=${course.id}&task_id=${task.id}`,
        text: task.name
      });
    parts.push({
      url: `/reports/stats?task_id=${task.id}`,
      text: "Statistics"
    });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
    return () => {};
  }, [course.name, dispatch, task.id, task.name, course.id, intl]);

  return isTeacher || isCourseAdmin || isAdmin ? (
    <TeacherTaskStatsView
      questions={questions}
      task={task}
      text={text}
      submissions={submissions}
      submittedSubmissionsIds={submittedSubmissionsIds}
    />
  ) : (
    <StudentTaskStatsView
      questions={questions}
      text={text}
      task={task}
      submissions={submissions}
      interactions={interactions}
      submittedSubmissionsIds={submittedSubmissionsIds}
    />
  );
};

export default TaskStatsView;
