import React, { useState, useEffect } from "react";

import { useIntl } from "react-intl";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Paper } from "@mui/material";

import { makeStyles } from "@mui/styles";
import PieChart from "./PieChart";
import { bucketSubmissionByStatus } from "./utils";

const useStyles = makeStyles((theme) => ({
  titleText: {
    lineHeight: "1",
    marginBottom: "0",
    padding: "24px 0",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  legend: {
    display: "flex",
    justifyContent: "space-around"
  },
  item: {
    width: "100%",
    padding: "24px 0",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    "&:last-child": {
      borderRight: "none"
    }
  },
  chart: {
    padding: "0"
  },
  label: {
    // fontSize: "16px !important",
    display: "inline-block",
    textAlign: "left",
    verticalAlign: "middle"
  },
  legendContainer: {
    width: "100%",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

export const Marker = ({ color, ...restProps }) => {
  return (
    <svg
      style={{ verticalAlign: "middle", marginRight: "5px" }}
      fill={color}
      width="12"
      height="12"
      {...restProps}>
      <circle r={6} cx={6} cy={6} {...restProps} />
    </svg>
  );
};

export const Label = ({ ...restProps }) => {
  const classes = useStyles();
  return (
    <Typography {...restProps} variant="caption" className={classes.label} />
  );
};

export const TitleText = ({ text, ...restProps }) => {
  const classes = useStyles();
  return (
    <Box component="header" {...restProps}>
      <Typography variant="subtitle1" className={classes.titleText}>
        {text}
      </Typography>
    </Box>
  );
};

export default function TimeManagmentStats({
  generalStatsHeight,
  submissions
}) {
  const theme = useTheme();
  const intl = useIntl();

  const classes = useStyles();
  const [timeManagement, setTimeManagement] = useState([]);

  useEffect(() => {
    const stats = bucketSubmissionByStatus(submissions);

    setTimeManagement([
      {
        cat: intl.formatMessage({
          id: "task.statistics.pending",
          defaultMessage: "Pending"
        }),
        val: stats.pending || 0,
        color: "#D8D8D8"
      },
      {
        cat: intl.formatMessage({
          id: "task.statistics.onTime",
          defaultMessage: "On time"
        }),
        val: stats.onTime || 0,
        color: theme.palette.secondary.main
      },
      {
        cat: intl.formatMessage({
          id: "task.statistics.late",
          defaultMessage: "Late"
        }),
        val: stats.late || 0,
        color: theme.palette.warning.main
      },
      {
        cat: intl.formatMessage({
          id: "task.statistics.missed",
          defaultMessage: "Missed"
        }),
        val: stats.missed || 0,
        color: theme.palette.error.main
      }
    ]);
  }, [
    submissions,
    intl,
    theme.palette.error.main,
    theme.palette.secondary.main,
    theme.palette.warning.main
  ]);

  return (
    <Paper elevation={0}>
      {/* Render the title */}
      <TitleText
        text={intl.formatMessage({
          id: "task.statistics.gradeAvrage",
          defaultMessage: "Time management"
        })}
      />

      {/* Render the chart using D3.js */}
      <PieChart data={timeManagement} width="100%" height={305} />

      {/* Render the legend */}
      <Box className={classes.legendContainer}>
        <Box className={classes.legend}>
          {timeManagement.map((dataPoint, index) => (
            <Box key={index} className={classes.item}>
              <Marker color={dataPoint.color} />
              <Label>{dataPoint.cat}</Label>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
}
