// Dependencies
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  flattenObject,
  formatLegendLabel,
  formatMillisecondsTime,
  formatDate,
  sortTimeSpentOnAssignmentObject,
  sumTaskById,
  extractAssignmentNumberFromString,
  adjustPercentages
} from "../utils";
import { isEmpty } from "lodash-es";
// Components
import { generateMarkers } from "../pieChart/PieChartLegend";

// Material-UI
import { Box, Typography, IconButton } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
  LEGEND_MARKER_FOR_COACH_USAGE,
  LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT
} from "../consts";
import { useGetTheme } from "../../../../hooks";
import { STACKED_GRAPH_TYPES } from "./consts.js";

function StackedGraphLegend({
  type,
  data,
  selectedAssignment,
  courseStudents
}) {
  // Hooks
  const navigate = useNavigate();
  const theme = useGetTheme();
  const [legendData, setLegendData] = useState([]);
  const isSubmissionsType = type === "submissions";

  useEffect(() => {
    if (isEmpty(data) || !selectedAssignment) return;
    if (selectedAssignment?.id) {
      let assignmentData;
      switch (type) {
        case STACKED_GRAPH_TYPES.SUBMISSIONS:
          assignmentData = sumTaskById(data.userBased, selectedAssignment.id);
          break;
        case STACKED_GRAPH_TYPES.COACH_USAGE_CHATS: {
          if (!data[selectedAssignment.id]) return;
          const { studentsEngPercentage, ...partialData } =
            data[selectedAssignment.id];
          assignmentData = partialData;
          break;
        }
        default:
          assignmentData = sortTimeSpentOnAssignmentObject(
            data[selectedAssignment.id]
          );
          break;
      }
      const chartData = Object.entries(flattenObject(assignmentData))
        .map((entry) => ({
          cat: entry[0],
          val: entry[1]
        }))
        .filter(
          (entry) =>
            !(
              entry.val <= 0 ||
              (isSubmissionsType &&
                selectedAssignment.id !==
                  extractAssignmentNumberFromString(entry.cat))
            )
        );
      const divisor = isSubmissionsType
        ? courseStudents.length
        : chartData.reduce((sum, item) => sum + item.val, 0);
      const chartDataPercentages = adjustPercentages(chartData, divisor);
      setLegendData(chartDataPercentages);
    }
  }, [selectedAssignment, data, isSubmissionsType]);

  const renderLegendItem = (key, value, percentage) => {
    const [part1, part2] = key.split(/_(.+)/);
    let markerKey;
    let legendText;
    switch (type) {
      case STACKED_GRAPH_TYPES.SUBMISSIONS:
        markerKey = part2;
        legendText = `${formatLegendLabel(markerKey)}: ${percentage}%`;
        break;
      case STACKED_GRAPH_TYPES.COACH_USAGE_CHATS:
        markerKey = `${key}`;
        legendText = `${LEGEND_MARKER_FOR_COACH_USAGE[markerKey.toUpperCase()]} ${percentage}%`;
        break;
      default:
        markerKey = `${key}_total`;
        legendText = `${
          LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT[markerKey]
        }${formatMillisecondsTime(value)}`;
        break;
    }

    if (type === STACKED_GRAPH_TYPES.COACH_USAGE_STUDENTS) return null;
    return (
      <Box
        key={key}
        sx={{
          display: "flex",
          alignItems: "center",
          marginInlineStart: "8px",
          marginInlineEnd: "8px",
          marginBlockEnd: "16px"
        }}>
        {generateMarkers(markerKey, value, theme)}
        <Typography
          variant="caption"
          sx={{ marginInlineStart: "8px", color: theme.palette.text.primary }}>
          {legendText}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        width: "100%",
        height: "30%",
        marginInlineStart: "24px",
        marginBlockStart: "24px"
      }}>
      <Box
        sx={{
          paddingInlineStart: "8px",
          width: "fit-content"
        }}>
        {selectedAssignment && (
          <Typography
            variant="subtitle2"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/reports/stats?task_id=${selectedAssignment.id}`);
            }}>
            {selectedAssignment?.name}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "row",
          marginBlockStart: "16px"
        }}>
        {isSubmissionsType && selectedAssignment && (
          <Box
            sx={{
              paddingInline: "8px",
              marginBlockEnd: "16px",
              minWidth: "fit-content",
              height: "min-content",
              borderRight: `1px solid ${theme.palette.text.primary}`
            }}>
            <IconButton onClick={() => {}} size="medium" sx={{ padding: 0 }}>
              <CalendarTodayOutlinedIcon fontSize="small" />
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                marginInline: "8px"
              }}>
              Due date: {formatDate(selectedAssignment.original_due_date)}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            width: "auto",
            display: "flex",
            flexFlow: "row wrap",
            paddingInlineEnd: "40px",
            paddingInlineStart: isSubmissionsType ? "8px" : 0
          }}>
          {legendData.map((item) =>
            renderLegendItem(item.cat, item.val, item.percentage)
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default StackedGraphLegend;
