// Dependencies
import React from "react";
// Components
import LineChartBody from "./LineChartBody";
// Redux

// Material-UI
import { Box } from "@mui/material";

const LineChart = ({ data, courseAssignments }) => {
  if (!data || !data.length) return;
  function getGradesBySubmission(submissions, courseAssignments) {
    const gradesByTask = {};

    submissions.forEach((submission) => {
      const { task_id, grade } = submission;
      if (task_id) {
        if (!gradesByTask[task_id]) {
          gradesByTask[task_id] = [];
        }
        const gradeToNormalized = grade;
        gradeToNormalized && gradesByTask[task_id].push(gradeToNormalized);
      }
    });

    let maxGrades = {};

    // Iterate through the tasks array to extract max grades
    courseAssignments.forEach((assignment) => {
      const normalizedMaxGrade = assignment.max_grade;
      maxGrades[assignment.id] = normalizedMaxGrade;
    });

    return { gradesByTask, maxGrades };
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
        alignItems: "flex-start"
      }}>
      <LineChartBody
        data={getGradesBySubmission(data, courseAssignments)}
        courseAssignments={courseAssignments}
      />
    </Box>
  );
};

export default LineChart;
