import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { reverseNormalizeGrade } from "../utils";
import { useGetTheme } from "../../../../hooks";
import { Box } from "@mui/material";

const GradeHistogram = ({ data, courseStudents, selectedAssignment }) => {
  const svgRef = useRef();
  const theme = useGetTheme();
  const isDark = theme.palette.mode === "dark";

  function processGrades(array, maxGrade) {
    return array
      .map((obj) => {
        let newObj = { ...obj };
        if (
          Object.prototype.hasOwnProperty.call(newObj, "grade") &&
          newObj.grade !== null
        ) {
          newObj.grade = reverseNormalizeGrade(Number(newObj.grade), maxGrade);
        }
        return newObj;
      })
      .filter(
        (obj) =>
          Object.prototype.hasOwnProperty.call(obj, "grade") &&
          obj.grade !== null
      );
  }

  useEffect(() => {
    d3.select(svgRef.current).selectAll("*").remove();

    let processedData = data;
    if (selectedAssignment && selectedAssignment.max_grade < 100) {
      processedData = data.filter((d) => d.task_id === selectedAssignment.id);
      processedData = processGrades(
        processedData,
        selectedAssignment.max_grade
      );
    }

    // Early return if no data
    if (!processedData || processedData.length === 0) {
      return;
    }

    // Calculate the grade distribution first
    const gradeDistribution = {};
    processedData.forEach((d) => {
      const grade = Math.round(d.grade);
      gradeDistribution[grade] = (gradeDistribution[grade] || 0) + 1;
    });

    const margin = { top: 30, right: 40, bottom: 70, left: 50 };
    const width = 850 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Get the maximum count from grade distribution
    const maxCount = Math.max(...Object.values(gradeDistribution));
    let yAxisMax;
    if (maxCount <= 5) {
      yAxisMax = maxCount + 1; // For very small counts, add just 1
    } else if (maxCount <= 10) {
      yAxisMax = Math.ceil(maxCount / 2) * 2; // Round up to nearest 2
    } else {
      yAxisMax = Math.ceil(maxCount / 5) * 5; // Round up to nearest 5
    }

    // Y-axis scale
    const yScale = d3.scaleLinear().domain([0, yAxisMax]).range([height, 0]);

    // Generate tick values with appropriate intervals based on the max count
    let yTickInterval;
    if (yAxisMax <= 5) {
      yTickInterval = 1; // Show every integer for small ranges
    } else if (yAxisMax <= 10) {
      yTickInterval = 2; // Show every other number for medium ranges
    } else {
      yTickInterval = Math.ceil(yAxisMax / 5); // Divide range into ~5 parts for larger ranges
    }

    const yTicks = d3
      .range(0, yAxisMax + 1, yTickInterval)
      .filter((tick) => Number.isInteger(tick));

    const yAxis = d3
      .axisLeft(yScale)
      .tickValues(yTicks)
      .tickFormat(d3.format("d"));

    // X-axis scale with explicit domain
    const xScale = d3
      .scaleLinear()
      .domain([0, 100]) // Explicitly set domain from 0 to 100
      .range([0, width]);

    const xAxis = d3
      .axisBottom(xScale)
      .tickValues([0, 25, 50, 75, 100]) // Explicit tick values
      .tickFormat((d) => `${Math.round(d)}%`);

    // Create SVG
    const svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create histogram with explicit thresholds
    // Create bins spanning from 0 to 100, making sure to include 0 and 100 explicitly
    const thresholds = [0, ...d3.range(2, 100, 2), 100];
    const histogram = d3
      .histogram()
      .value((d) => d.grade)
      .domain([0, 100])
      .thresholds(thresholds);

    const bins = histogram(processedData);
    const binWidth = width / (thresholds.length - 1);

    // Helper function to get consistent bin counts
    const getBinCount = (bin, data) => {
      return data.filter((d) => {
        if (bin.x0 === 0) {
          // First bin - include exactly 0%
          return d.grade >= 0 && d.grade < bin.x1;
        } else if (bin.x1 === 100) {
          // Last bin - include exactly 100%
          return d.grade > bin.x0 && d.grade <= 100;
        }
        // All other bins - exclude lower bound, include upper bound
        return d.grade > bin.x0 && d.grade <= bin.x1;
      }).length;
    };

    // Generate curve points
    const curvePoints = [];
    bins.forEach((bin) => {
      const actualCount = getBinCount(bin, processedData);

      // Special handling for tooltip display values for edge cases
      let centerPoint;
      if (bin.x0 === 0 && bin.x1 > 0) {
        // First bin - explicitly set center to 0 for display
        centerPoint = 0;
      } else if (bin.x0 < 100 && bin.x1 === 100) {
        // Last bin before 100 - explicitly set center to 100 for display
        centerPoint = 100;
      } else {
        // Regular bins - use the mathematical center
        centerPoint = (bin.x0 + bin.x1) / 2;
      }

      // Debug logging for the first and last bins
      if (bin.x0 === 0 || bin.x1 === 100) {
        console.log(
          `Bin ${bin.x0}-${bin.x1} has ${actualCount} students, displayed as ${centerPoint}%`
        );
      }

      curvePoints.push({
        x: xScale(centerPoint),
        y: yScale(actualCount),
        grade: centerPoint, // Store for tooltip
        count: actualCount // Store for tooltip
      });
    });

    // Draw the curve
    const line = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(d3.curveMonotoneX);

    svg
      .append("path")
      .datum(curvePoints)
      .attr("fill", "none")
      .attr("stroke", isDark ? theme.palette.primary.dark : "#5E92F3")
      .attr("stroke-width", 1.5)
      .attr("d", line);

    // Add y-axis
    svg
      .append("g")
      .call(yAxis)
      .call((g) => g.select(".domain").remove());

    // Add x-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis)
      .call((g) => g.select(".domain").remove());

    // Add grid lines
    svg
      .append("g")
      .attr("class", "grid")
      .attr("opacity", 0.1)
      .call(
        d3
          .axisLeft(yScale)
          .tickValues(yScale.ticks(6))
          .tickSize(-width)
          .tickFormat("")
      );

    // Style text
    svg
      .selectAll(".tick text")
      .style("fill", theme.palette.text.primary)
      .style("font-size", "12px")
      .style("font-weight", "500")
      .style("font-family", "Chivo");

    // Add y-axis label
    svg
      .append("text")
      .attr("x", -20)
      .attr("y", -10)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .style("font-size", "14px")
      .style("font-weight", "500")
      .style("font-family", "Chivo")
      .style("color", theme.palette.text.secondary)
      .text("Number of students");

    // Add tooltips
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("border-radius", "5px")
      .style("background-color", "rgba(97, 97, 97, 0.9)")
      .style("color", "white")
      .style("padding", "4px 8px")
      .style("font-size", "12px")
      .style("pointer-events", "none")
      .style("z-index", "1000");

    // Add tooltip overlay rectangles using consistent data with the curve
    curvePoints.forEach((point, i) => {
      const bin = bins[i];

      svg
        .append("rect")
        .attr("class", "overlay")
        .attr("x", xScale(bin.x0))
        .attr("y", 0)
        .attr("width", binWidth)
        .attr("height", height)
        .attr("fill", "transparent")
        .on("mouseover", (event) => {
          tooltip.transition().duration(200).style("opacity", 1);
          tooltip
            .html(
              `Grade: ${Math.round(point.grade)}%<br>Students: ${point.count}`
            )
            .style("left", `${event.pageX + 10}px`)
            .style("top", `${event.pageY - 28}px`);
        })
        .on("mouseout", () => {
          tooltip.transition().duration(500).style("opacity", 0);
        });
    });

    return () => {
      d3.select("body").selectAll(".tooltip").remove();
    };
  }, [data, courseStudents.length, selectedAssignment, theme, isDark]);

  return (
    <Box
      style={{
        overflow: "hidden",
        height: "100%",
        display: "flex",
        justifyContent: "center"
      }}>
      <div ref={svgRef} />
    </Box>
  );
};

export default GradeHistogram;
