// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

// Components
import OpenQuestionStats from "./OpenQuestionStats";
import MultiChoiceStats from "./MultiChoiceStats";
import FindInTextStats from "./FindInTextStats";
import StudentEvaluation from "./StudentEvaluation";
import ScrollBox from "../../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Paper } from "@mui/material";
import { PangeaSpinner } from "../../SharedComponents";
// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%"
  },
  content: {
    width: "800px",
    margin: "0 auto"
  },
  pageTitle: {
    marginTop: theme.spacing(5),
    marginBottom: "24px"
  },
  question: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    gridColumn: "1 / 4",
    boxShadow: 2
  },
  studentAnswer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.grey[50]
  },
  answerLabel: {
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  }
}));

export default function StudentTaskStatsView({
  questions,
  task,
  text,
  submissions,
  submittedSubmissionsIds,
  interactions
}) {
  const classes = useStyles();
  const intl = useIntl();
  const [annonymize, setAnnonymize] = useState(true);

  // Function to find student answer for a question
  const findStudentAnswer = (questionId) => {
    return interactions.find(
      (interaction) => interaction.interaction_id === questionId
    );
  };

  if (questions.length)
    return (
      <Box className={classes.container}>
        <ScrollBox>
          <Box className={classes.content}>
            <Typography
              className={clsx(classes.left, classes.pageTitle)}
              variant="h5">
              {intl.formatMessage({
                id: "task.statistics.pageTitle",
                defaultMessage: "Assignment heat maps"
              })}
            </Typography>
            <Box component="header">
              {questions.map((question, index) => {
                const studentAnswer = findStudentAnswer(question.id);

                return (
                  <React.Fragment key={question.id}>
                    {question.interaction_subtype === "MULTI_CHOICE" && (
                      <Box key={question.id} className={classes.question}>
                        <MultiChoiceStats
                          submissions={submissions}
                          question={question}
                          index={index}
                          task={task}
                          text={text}
                          isTeacher={false}
                          submittedSubmissionsIds={submittedSubmissionsIds}
                        />
                        {studentAnswer && (
                          <Paper
                            className={classes.studentAnswer}
                            elevation={0}>
                            <Typography className={classes.answerLabel}>
                              {`Your Answer to Question #${Number(index) + 1}:`}
                            </Typography>
                            <Typography>{studentAnswer.content}</Typography>
                          </Paper>
                        )}
                      </Box>
                    )}
                    {question.interaction_subtype === "FIND_IN_TEXT" && (
                      <Box key={index} className={classes.question}>
                        <FindInTextStats
                          submissions={submissions}
                          question={question}
                          index={index}
                          task={task}
                          text={text}
                          isTeacher={false}
                          submittedSubmissionsIds={submittedSubmissionsIds}
                        />
                        {studentAnswer && (
                          <Paper
                            className={classes.studentAnswer}
                            elevation={0}>
                            <Typography className={classes.answerLabel}>
                              {`Your Answer to Question #${Number(index) + 1}:`}
                            </Typography>
                            <Typography>{studentAnswer.content}</Typography>
                          </Paper>
                        )}
                      </Box>
                    )}

                    {["OPEN_ENDED"].includes(question.interaction_subtype) && (
                      <Box key={index} className={classes.question}>
                        <OpenQuestionStats
                          submissions={submissions}
                          question={question}
                          index={index}
                          task={task}
                          text={text}
                          isTeacher={false}
                          annonymizeForm={annonymize}
                          submittedSubmissionsIds={submittedSubmissionsIds}
                        />
                        {studentAnswer && (
                          <Paper
                            className={classes.studentAnswer}
                            elevation={0}>
                            <Typography className={classes.answerLabel}>
                              {`Your Answer to Question #${Number(index) + 1}:`}
                            </Typography>
                            <Typography>{studentAnswer.content}</Typography>
                          </Paper>
                        )}
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
              <StudentEvaluation submissions={submissions} />
            </Box>
          </Box>
        </ScrollBox>
      </Box>
    );
  else return <PangeaSpinner />;
}
