import { useSelector } from "react-redux";
import { auth } from "../firebase";
import { useEffect, useState, useCallback } from "react";

export const CLAIMS_ROLES = {
  ADMIN: "admin",
  DATA_VIEWER: "dataViewer"
};

function useUserClaim(role) {
  const currentUser = useSelector((state) => state.user.auth);
  const [claim, setClaim] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchClaim = useCallback(async () => {
    try {
      const idTokenResult = await auth.currentUser.getIdTokenResult(true);
      setClaim(idTokenResult.claims?.role);
    } catch (error) {
      console.error("Error fetching claims:", error);
      setClaim(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchClaim();
  }, [currentUser, fetchClaim]);

  if (role) {
    return Boolean(claim === role);
  }

  return { claim, isLoading };
}

export default useUserClaim;
