import React from "react";
import { Routes, Route } from "react-router";
import * as Sentry from "@sentry/react";

import StudentAnalytics from "../components/SharedComponents/studentAnalytics/StudentAnalytics";
import CourseExternalReport from "../components/SharedComponents/reports/CourseExternalReport";
import TaskStatsView from "../components/Tasks/Stats/TaskStatsView";

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

export default function ReportsRoutes() {
  return (
    <SentryRoutes>
      <Route path="student" element={<StudentAnalytics />} />
      <Route path="courseExternal" element={<CourseExternalReport />} />
      <Route path="stats" element={<TaskStatsView />} />
    </SentryRoutes>
  );
}
