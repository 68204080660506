import React, { useEffect, useState, useCallback } from "react";
import {
  getSessionAveragesPerTaskAndStudent,
  formatMillisecondsTime
} from "./utils";
import { Box, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";
import { useGetTheme } from "../../../hooks";

const CourseOverview = ({
  activeUsersNum,
  submissionRate,
  totalAssignments,
  timeSpentOnAssignmentData
}) => {
  const theme = useGetTheme();
  const [averageEngagement, setAverageEngagement] = useState(0);
  const items = [
    {
      title: "Active students",
      value: activeUsersNum
    },
    {
      title: "Course assignments",
      value: totalAssignments
    },
    {
      title: "Submission rate",
      value: submissionRate ? `${submissionRate.toFixed(0)}%` : "-"
    },
    {
      title: "Average engagement per assignment",
      value: averageEngagement || 0
    }
  ];

  const sumTotals = useCallback(
    (data) => {
      if (isEmpty(data)) return;

      let totalSum = 0;
      for (let key in data) {
        if (Object.prototype.hasOwnProperty.call(data[key], "total")) {
          totalSum += data[key].total;
        }
      }
      return totalSum;
    },
    [activeUsersNum, totalAssignments]
  );

  useEffect(() => {
    if (!timeSpentOnAssignmentData || !activeUsersNum || !totalAssignments)
      return;
    let [transformedData] = getSessionAveragesPerTaskAndStudent(
      timeSpentOnAssignmentData
    );

    let averageTimePerAssignment = sumTotals(transformedData);
    if (averageTimePerAssignment !== undefined) {
      averageTimePerAssignment = formatMillisecondsTime(
        averageTimePerAssignment
      );

      setAverageEngagement(averageTimePerAssignment);
    }
  }, [timeSpentOnAssignmentData, activeUsersNum, totalAssignments, sumTotals]);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        margin: 0,
        padding: 0
      }}>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            flex: "1 1 calc(25% - 16px)",
            marginInlineEnd: index % 4 !== 3 ? "16px" : 0,
            height: "130px",
            border: `1px solid ${theme.palette.border.secondary}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            backgroundColor: theme.palette.background.paper
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexFlow: "column",
              height: "100%",
              padding: "10%",
              textAlign: "center"
            }}>
            <Typography variant="p" sx={{}}>
              {item.title}
            </Typography>
            <Typography variant="h4">{item.value}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CourseOverview;
