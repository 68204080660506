// Dependencies
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { stemmer } from "stemmer";
import FlexSearch from "flexsearch";
import { useDispatch, useSelector } from "react-redux";
import { normalize, normalizeNum } from "./utils";
import { selectCurrentText } from "../../../redux/textsSlice";
import { useIntl } from "react-intl";

// Redux
import {
  selectQuestionHighlights,
  selectQuestionAnswers,
  selectTaskFeedbacks,
  updateInteraction
} from "../../../redux/interactionsSlice";

// Components
import OpenQuestionStudentAnswer from "./OpenQuestionStudentAnswer";
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import { USER_TYPE } from "../../../consts";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    statContainer: {
      paddingTop: "32px"
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold"
    },
    OpenQuestionContainer: {
      display: "flex",
      flexDirection: "row"
    },
    citationContainer: {
      width: "50%",
      padding: "16px",
      marginRight: "16px"
    },
    statsRightSection: {
      width: "50%",
      display: "flex",
      flexWrap: "wrap",
      gap: "36px",
      marginBottom: "32px"
    },
    stat: {
      flex: "0 0 calc(50% - 18px)",
      minWidth: "200px"
      // height: "300px"
      // "& > div": {
      //   height: "100%"
      // }
    },
    statTitle: {
      marginBottom: "16px"
    },
    statValuesContainer: {
      display: "flex",
      justifyContent: "space-between",
      overflow: "hidden",
      lineHeight: "initial",
      marginBottom: "6px"
    },
    statKey: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: theme.palette.primary.main,
      "&:hover": {
        overflow: "initial"
      }
    },
    statBar: {
      height: "8px",
      backgroundColor: "#D8D8D8",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: theme.palette.primary.main
      }
    },
    answers: {
      borderRadius: "4px",
      backgroundColor: theme.palette.primary.main,
      "& .MuiAccordionSummary-content": {
        order: 2
      }
    },
    answersHeading: {
      //   fontSize: theme.typography.pxToRem(15),
      color: "#FFFFFF",
      padding: "0 16px"
    },
    answersDetails: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      backgroundColor: theme.palette.background.paper,
      borderRadius: " 0 0 4px 4px"
    },
    answer: {
      marginBottom: "16px",
      breakInside: "avoid",
      pageBreakInside: "avoid",
      cursor: "pointer"
    },
    answerBody: {
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": "6",
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis"
    },
    answerActions: {
      padding: "16px",
      color: "#168FEE"
    },
    citationsActions: {
      textAlign: "end",
      paddingBlock: "24px"
    },
    actionButton: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
      padding: "0px 8px",
      height: "40px"
    },
    questionSubtitle: {
      width: "600px"
    }
  };
});

export default function OpenQuestionStats({
  question,
  index, // the index of the question
  task,
  submissions,
  annonymizeForm,
  isTeacher = true,
  submittedSubmissionsIds = []
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  // Redux State
  const textDirection = useSelector((state) => selectTextDirection(state));
  const questionHighlights = useSelector(
    (state) => selectQuestionHighlights(state, question.id),
    (a, b) => JSON.stringify(a.length) === JSON.stringify(b.length)
  );
  const questionAnswers = useSelector(
    (state) => selectQuestionAnswers(state, question.id),
    (a, b) => JSON.stringify(a.length) === JSON.stringify(b.length)
  );

  const taskFeedbacks = useSelector((state) =>
    selectTaskFeedbacks(state, task.id)
  );

  const text = useSelector(selectCurrentText);
  // Ephemeral State
  const [openHeatmap, setOpenHeatmap] = useState(false);
  const [pinnedItems, setPinnedItems] = useState([]);

  // Derived State
  const withCitations = question.includeCitation;
  const concepts = question.concepts || [];

  const submittedSubmissions = submissions.filter(
    (submission) => submission.submission_date
  );
  const questionFeedbacks = taskFeedbacks.filter(
    (feedback) =>
      submittedSubmissionsIds.includes(feedback.submission_id) &&
      feedback.interaction_id === question.id
  );

  const participants = submittedSubmissionsIds.length;
  let points = questionFeedbacks.map((feedback) => feedback.points || 0);
  const sum = points.reduce((a, b) => Number(a) + Number(b), 0);
  const avg = points.length > 0 ? sum / points.length : 0;

  // this is what it is now
  const teacherHighlights = question.quotes || [];
  const studentHighlights = questionHighlights.filter(
    (highlight) => highlight.user_type === USER_TYPE.STUDENT
  );

  const togglePin = (itemObject) => {
    const itemId = itemObject.id;
    const itemIndex = pinnedItems.findIndex((item) => item.id === itemId);

    // Toggle the pinned status
    const updatedItem = {
      ...itemObject,
      pinned: !(itemObject?.pinned === true)
    };

    // Dispatch the update interaction
    dispatch(
      updateInteraction({
        interaction: updatedItem,
        update: { pinned: updatedItem.pinned }
      })
    );

    setPinnedItems((questionAnswers) => {
      // Remove the item from its current position
      let updatedQuestionAnswers = [
        ...questionAnswers.slice(0, itemIndex),
        ...questionAnswers.slice(itemIndex + 1)
      ];

      if (updatedItem.pinned) {
        // If the item is now pinned, move it to the top
        updatedQuestionAnswers.unshift(updatedItem);
      } else {
        // If the item is now unpinned, move it to the end of the array
        updatedQuestionAnswers.push(updatedItem);
      }

      return updatedQuestionAnswers;
    });
  };

  useEffect(() => {
    if (!questionAnswers.length) return;

    const sortedQuestionAnswers = [...questionAnswers].sort((a, b) => {
      if (
        Object.prototype.hasOwnProperty.call(a, "pinned") &&
        a.pinned === true
      ) {
        return -1; // a comes first
      } else if (
        Object.prototype.hasOwnProperty.call(b, "pinned") &&
        b.pinned === true
      ) {
        return 1; // b comes first
      } else {
        return 0; // no change in order
      }
    });

    setPinnedItems(sortedQuestionAnswers);
  }, [questionAnswers]);

  // index concepts in answers
  const createIndex = () => {
    let flexIndex = new FlexSearch.Index({
      stemmer: function (value) {
        // apply some replacements
        // ...

        return stemmer(value);
      },
      tokenize: "forward",
      rtl: textDirection === "rtl",
      split: textDirection === "rtl" ? /\s+/ : /\W+/
    });

    questionAnswers.forEach((answer, index) => {
      flexIndex.add(index, answer.content);
    });

    // changed to an object with the concept as key and result as value, easier to access in the render
    let output = {};
    if (question.concepts) {
      question.concepts.forEach((concept) => {
        let results = flexIndex.search(stemmer(concept));
        let exactResults = flexIndex.search(concept);
        output[concept] = Math.max(results.length, exactResults.length);
      });
    }
    return output;
  };

  const conceptResults = createIndex(index, questionAnswers);

  if (isTeacher)
    return (
      <>
        <HeatMap
          totalCount={submittedSubmissionsIds.length}
          openDialog={openHeatmap}
          teacherHighlights={teacherHighlights}
          setOpenDialog={setOpenHeatmap}
          url={text.url}
          location={text.file_location}
          hlOpacity={1}
          hlColor="#4AA96C"
          highlights={studentHighlights}
          addHighlight={() => {}}
          removeHighlight={() => {}}
        />
        <Paper component="section" elevation={0}>
          <Box
            component="header"
            sx={{ paddingTop: "32px", paddingBottom: "16px" }}>
            <Typography variant="h6">
              {intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}{" "}
              #{index + 1} —{" "}
              {intl.formatMessage({
                id: "task.type.open",
                defaultMessage: "Open Ended"
              })}
            </Typography>
            <Typography
              className={clsx(classes.questionSubtitle)}
              variant="subtitle1">
              {question.content}
            </Typography>
          </Box>
          <Box className={classes.OpenQuestionContainer}>
            {withCitations && (
              <Box className={classes.citationContainer}>
                <CitationChart
                  question={question}
                  i={index}
                  task={task}
                  submissions={submittedSubmissions}
                  answers={studentHighlights}
                />
              </Box>
            )}
            <Box className={classes.statsRightSection}>
              <Box className={clsx(classes.stat, classes.statContainer)}>
                <Typography className={classes.statTitle} variant="subtitle1">
                  {intl.formatMessage({
                    id: "task.statistics.gradeAvrage",
                    defaultMessage: "Grade average"
                  })}
                </Typography>
                <Box className={classes.statValuesContainer}>
                  <Box component="span" className={classes.statKey}>
                    {Number(avg).toFixed(1)}
                  </Box>
                  <Box component="span">{`of ${question.points}`}</Box>
                </Box>
                <LinearProgress
                  aria-label="stats bar"
                  variant="determinate"
                  value={normalizeNum(avg, 0, question.points)}
                  className={classes.statBar}
                />
              </Box>
              {concepts.map((concept, index) => (
                <Box
                  key={index}
                  className={clsx(classes.stat, classes.statContainer)}>
                  <Typography className={classes.statTitle} variant="subtitle1">
                    {intl.formatMessage({
                      id: "task.statistics.keyword",
                      defaultMessage: "Keyword"
                    })}
                  </Typography>
                  <Box className={classes.statValuesContainer}>
                    <Box component="span" className={classes.statKey}>
                      {concept}
                    </Box>
                    <Box component="span">
                      {`${normalize(
                        conceptResults[concept],
                        0,
                        participants
                      )}%`}
                    </Box>
                  </Box>
                  <LinearProgress
                    aria-label="stats bar"
                    variant="determinate"
                    value={normalizeNum(
                      conceptResults[concept],
                      0,
                      participants
                    )}
                    className={classes.statBar}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          {withCitations && (
            <Box className={classes.citationsActions}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disableElevation
                className={classes.actionButton}
                onClick={() => setOpenHeatmap(true)}>
                {intl.formatMessage({
                  id: "task.statistics.showHeatmap",
                  defaultMessage: "Show heatmap"
                })}
              </Button>
            </Box>
          )}
        </Paper>
        <Accordion
          className={classes.answers}
          elevation={0}
          disabled={submittedSubmissions.length === 0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="details-stats"
            id="panel-header">
            <Typography variant="subtitle1" className={classes.answersHeading}>
              {intl.formatMessage({
                id: "task.statistics.studentsAnswers",
                defaultMessage: "Students answers"
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            id="details-stats"
            className={classes.answersDetails}>
            {pinnedItems.map((answer) => (
              <OpenQuestionStudentAnswer
                key={answer.id}
                answer={answer}
                index={index} // we are passing the index of the question in order to be able to navigate to the question. A better solution would be to use the question id from answer.interaction_id
                annonymizeForm={annonymizeForm}
                togglePin={togglePin}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </>
    );
  else
    return (
      <>
        <Card
          component="section"
          elevation={1}
          className={classes.statContainer}>
          <HeatMap
            totalCount={submittedSubmissionsIds.length}
            openDialog={openHeatmap}
            teacherHighlights={teacherHighlights}
            setOpenDialog={setOpenHeatmap}
            url={text.url}
            location={text.file_location}
            hlOpacity={1}
            hlColor="#4AA96C"
            highlights={studentHighlights}
            addHighlight={() => {}}
            removeHighlight={() => {}}
          />

          <Box component="header">
            <Typography
              variant="h6"
              style={{
                marginBottom: "15px"
              }}>
              {intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}{" "}
              #{index + 1} —{" "}
              {intl.formatMessage({
                id: "task.type.open",
                defaultMessage: "Open Ended"
              })}
            </Typography>
            <Divider />
            <Box
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                paddingTop: "15px"
              }}>
              <Typography
                className={clsx(classes.questionSubtitle)}
                variant="subtitle1">
                {question.content}
              </Typography>
              {withCitations && (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disableElevation
                  className={classes.actionButton}
                  onClick={() => setOpenHeatmap(true)}>
                  <Typography variant="p">
                    {intl.formatMessage({
                      id: "task.statistics.showHeatmap",
                      defaultMessage: "Show heatmap"
                    })}
                  </Typography>{" "}
                </Button>
              )}
            </Box>
          </Box>
        </Card>
      </>
    );
}
