import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { useChartDimensions } from "../../../hooks";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    position: "relative" // Important for tooltip positioning
  }
}));

export default function PieChart({ data, width, height }) {
  const { ref, dimensions } = useChartDimensions({});
  const classes = useStyles();
  const chartRef = useRef();

  useEffect(() => {
    if (dimensions.width > 0 && dimensions.height > 0) {
      // Remove any existing elements
      d3.select(chartRef.current).selectAll("*").remove();

      const pie = d3.pie().value((d) => d.val);
      const arcGenerator = d3
        .arc()
        .innerRadius((0.5 * Math.min(dimensions.width, dimensions.height)) / 2)
        .outerRadius((0.8 * Math.min(dimensions.width, dimensions.height)) / 2);

      const arcs = pie(data);

      const svg = d3
        .select(chartRef.current)
        .append("svg")
        .attr("width", dimensions.width)
        .attr("height", dimensions.height);

      const g = svg
        .append("g")
        .attr(
          "transform",
          `translate(${dimensions.width / 2}, ${dimensions.height / 2})`
        );

      // Create tooltip div at document body level
      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("position", "fixed") // Changed to fixed
        .style("color", "#fff")
        .style("padding", "5px 10px")
        .style("border-radius", "5px")
        .style("font-size", "12px")
        .style("background-color", "rgba(97, 97, 97, 0.9)")
        .style("pointer-events", "none") // Prevent tooltip from interfering with mouse events
        .style("z-index", "1000"); // Ensure tooltip appears above other elements

      g.selectAll("path")
        .data(arcs)
        .enter()
        .append("path")
        .attr("d", arcGenerator)
        .attr("fill", (d, i) => data[i].color)
        .on("mouseover", (event, d) => {
          // Get mouse position relative to viewport
          const [x, y] = d3.pointer(event, document.body);
          tooltip.transition().duration(200).style("opacity", 0.9);

          tooltip
            .html(`Value: ${d.value}`)
            .style("left", `${x + 10}px`) // Offset from cursor
            .style("top", `${y - 28}px`); // Position above cursor
        })
        .on("mousemove", (event) => {
          // Update tooltip position on mouse move
          const [x, y] = d3.pointer(event, document.body);
          tooltip.style("left", `${x + 10}px`).style("top", `${y - 28}px`);
        })
        .on("mouseout", () => {
          tooltip.transition().duration(500).style("opacity", 0);
        });

      // Cleanup function
      return () => {
        tooltip.remove();
      };
    }
  }, [data, dimensions]);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        width: "70%",
        height: "300px"
      }}
      ref={ref}
      className={classes.chartContainer}>
      <div ref={chartRef} />
    </Box>
  );
}
