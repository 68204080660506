// Dependencies
import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { useGetTheme } from "../../../hooks";
//Redux Dependencies

// Components
import GradeDistributionStats from "./GradeDistributionStats";
import OpenQuestionStats from "./OpenQuestionStats";
import MultiChoiceStats from "./MultiChoiceStats";
import FindInTextStats from "./FindInTextStats";
import ScrollBox from "../../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Chip, Typography } from "@mui/material";
import useCreatePortal from "../../../hooks/useCreatePortal";
import FaceRetouchingOffOutlinedIcon from "@mui/icons-material/FaceRetouchingOffOutlined";
import HeaderButtonConstructor from "../../SharedComponents/HeadeButtonConstructor";
import {
  fetchAssignmentData,
  processSubmissionsStatus
} from "../../SharedComponents/reports/utils";
import AssignmentOverview from "./AssignmentOverview";
import { CHART_NAMES } from "../../admin/reports/consts";
import AssignmentEngagementTime from "./AssignmentEngagementTime";
import CustomSkeleton from "../../SharedComponents/CustomSkeleton";
import SubmissionsStatusesStats from "./SubmissionsStatusesStats";

// Styles
const useStyles = makeStyles(() => {
  return {
    container: {
      width: "100%",
      maxHeight: "100%"
    },
    content: {
      width: "75%",
      margin: "40px auto"
    },
    lightIcon: {
      color: "#FFFFFF",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      }
    },
    activeIcon: {
      backgroundColor: "#42A5F5",
      border: "1px solid #42A5F5",
      borderRadius: "8px",
      color: "#FFFFFF",
      "&:hover": {
        background: "#42A5F5"
      }
    }
  };
});

export default function TeacherTaskStatsView({
  task,
  text,
  questions,
  submittedSubmissionsIds
}) {
  // Hooks
  const classes = useStyles();
  const theme = useGetTheme();
  const AnnonymizePortal = useCreatePortal(
    document && document.getElementById("global-annonymize-btn")
  );

  // Redux State

  // Ephemeral State
  const [annonymize, setAnnonymize] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState(false);
  const [submissionsStatus, setSubmissionsStatus] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetchAssignmentData(task.id, task.course_id);
      if (response.data.success) {
        setSubmissionsStatus(
          processSubmissionsStatus(
            response.data.payload[CHART_NAMES.SUBMISSIONS_STATUS]
          )
        );
        setStats(response.data.payload);
        setIsLoading(false);
      }
    };
    if (task?.id) {
      fetchData();
    }
  }, [task]);

  const engagementData = useMemo(() => {
    if (!stats) return {};

    // The data is already in the format we want, just return it directly
    const sessions = {
      HIGHLIGHT_SESSION: stats.HIGHLIGHT_SESSION || { total: 0 },
      REVIEW_SESSION: stats.REVIEW_SESSION || { total: 0 },
      ANSWER_SESSION: stats.ANSWER_SESSION || { total: 0 }
    };

    ["HIGHLIGHT_SESSION", "REVIEW_SESSION", "ANSWER_SESSION"].forEach(
      (type) => {
        sessions[type].total = stats["STATS"]?.activeTaskStudents?.length
          ? stats[type].total / stats["STATS"].activeTaskStudents?.length
          : stats[type].total;
      }
    );
    return sessions;
  }, [stats]);

  return (
    <Box className={classes.container}>
      <ScrollBox>
        <AnnonymizePortal>
          <HeaderButtonConstructor
            intlStringId="menu.tooltip.annonimize"
            defaultMessage="Anonymized view"
            placement="bottom"
            iconLabel="Anonymized view"
            className={clsx(
              classes.lightIcon,
              annonymize && classes.activeIcon
            )}
            handleClick={() => {
              setAnnonymize(!annonymize);
            }}
            icon={<FaceRetouchingOffOutlinedIcon />}
          />
        </AnnonymizePortal>
        <Box className={classes.content}>
          <Typography variant="h6">{task.name}</Typography>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ marginBlockEnd: "16px", marginBlockStart: "32px" }}>
              Assignment overview
            </Typography>
            <Box sx={{ width: "100%" }}>
              {isLoading ? (
                <CustomSkeleton height={"130px"} />
              ) : (
                <AssignmentOverview
                  activeCourseStudents={
                    stats["STATS"].activeCourseStudents.length
                  }
                  activeTaskStudents={stats["STATS"].activeTaskStudents.length}
                  submissionRate={stats["STATS"].submissionRate}
                  engagement={stats["STATS"].engagement}
                />
              )}
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "40px",
                marginBottom: "16px"
              }}>
              <Typography variant="subtitle1" sx={{ marginRight: "8px" }}>
                Submissions
              </Typography>
              <Chip
                variant="outlined"
                label={`${submittedSubmissionsIds.length} submissions`}
                sx={{ height: "24px" }}
              />
            </Box>
            <Box>
              {isLoading ? (
                <CustomSkeleton height={"400px"} />
              ) : (
                <SubmissionsStatusesStats
                  submissions={submissionsStatus}
                  totalSubmissions={
                    stats[CHART_NAMES.SUBMISSIONS_STATUS].rawSubmissions.length
                  }
                />
              )}
            </Box>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: "40px", marginBottom: "16px" }}>
              Engagement time
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <AssignmentEngagementTime
                data={engagementData}
                totalSubmissions={
                  stats[CHART_NAMES.SUBMISSIONS_STATUS].totalSubmissions
                }
                activeUsers={stats["STATS"].activeCourseStudents.length}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "40px" }}>
            <Typography variant="subtitle1" sx={{ marginBottom: "16px" }}>
              Questions
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"600px"} />
            ) : (
              <Box
                sx={{
                  paddingInline: "24px",
                  paddingBottom: "16px",
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.border.secondary}`,
                  borderRadius: "5px"
                }}>
                {questions.map((question, index) => {
                  return (
                    <Box
                      key={question.id}
                      sx={{
                        paddingBottom: "24px",
                        borderBottom:
                          index < questions.length - 1
                            ? `1px solid ${theme.palette.border.secondary}`
                            : "0"
                      }}>
                      {question.interaction_subtype === "MULTI_CHOICE" && (
                        <Box key={question.id}>
                          <MultiChoiceStats
                            submissions={
                              stats[CHART_NAMES.SUBMISSIONS_STATUS]
                                .rawSubmissions
                            }
                            question={question}
                            index={index}
                            task={task}
                            text={text}
                            submittedSubmissionsIds={submittedSubmissionsIds}
                          />
                        </Box>
                      )}
                      {question.interaction_subtype === "FIND_IN_TEXT" && (
                        <Box key={index}>
                          <FindInTextStats
                            submissions={
                              stats[CHART_NAMES.SUBMISSIONS_STATUS]
                                .rawSubmissions
                            }
                            question={question}
                            index={index}
                            task={task}
                            text={text}
                            submittedSubmissionsIds={submittedSubmissionsIds}
                          />
                        </Box>
                      )}

                      {["OPEN_ENDED"].includes(
                        question.interaction_subtype
                      ) && (
                        <Box key={index}>
                          <OpenQuestionStats
                            submissions={
                              stats[CHART_NAMES.SUBMISSIONS_STATUS]
                                .rawSubmissions
                            }
                            question={question}
                            index={index}
                            task={task}
                            text={text}
                            submittedSubmissionsIds={submittedSubmissionsIds}
                            annonymizeForm={annonymize}
                          />
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ marginTop: "40px", marginBottom: "16px" }}>
              Grades
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <GradeDistributionStats
                data={stats[CHART_NAMES.SUBMISSIONS_STATUS].rawSubmissions}
                courseStudents={stats["STATS"].activeCourseStudents}
                assignment={stats["TASK"]}
              />
            )}
          </Box>
        </Box>
      </ScrollBox>
    </Box>
  );
}
